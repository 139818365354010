import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import tw from 'tailwind-styled-components'

type DropzonePropsT = {
  multiple: boolean
  onChange: (e: any) => void
}

const StyledDropzone = tw.div`
w-full
border
bg-secondary
flex
flex-col
items-center
p-6
cursor-pointer
`

export const Dropzone: React.FC<DropzonePropsT> = ({ multiple, onChange, ...rest }) => {
  const [files, setFiles] = useState<any[]>([])
  const { t } = useTranslation()
  const onDrop = useCallback((acceptedFiles) => {
    // Only one file allowed currently
    setFiles([...acceptedFiles])
    acceptedFiles.forEach((file: Blob) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result
        onChange(binaryStr)
      }
      reader.readAsArrayBuffer(file)
    })
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple, ...rest })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps({ onChange })} />
      <StyledDropzone>
        <div>
          <CloudUploadIcon fontSize='large' />
        </div>
        <div>
          <div>{t('components.file')}</div>
          {files.map((file, index) => (
            <li key={index}>{file.path}</li>
          ))}
        </div>
      </StyledDropzone>
    </div>
  )
}
