import React from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'tailwind-styled-components'

import { FORMAT } from '../../../../constants/format'
import { RequestDetail } from '../../../../types/types'
import { formatDateWithIncomingFormat } from '../../../../utils/format'

const DetailContentWrapper = tw.div`
  grid
  gap-2
  text-sm
  py-3
`

interface HistoryContentProps {
  history: RequestDetail['historie']
}

export const HistoryContent: React.FC<HistoryContentProps> = ({ history }) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  return (
    <DetailContentWrapper>
      {history.map((historyAction, index) => (
        <div key={index}>{` ${formatDateWithIncomingFormat(
          historyAction.datum,
          FORMAT.API_DDMMYYYY_HHMM,
          'DD. MMM., HH:mm:ss',
          resolvedLanguage
        )}${t('modals.request_created_by', {
          name: `${historyAction.uzivatel.jmeno} ${historyAction.uzivatel.prijmeni}`,
        })}`}</div>
      ))}
    </DetailContentWrapper>
  )
}
