import { Badge, BadgeProps, Button, ButtonGroup } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'
import { useApiValueInLang } from '../../hooks/useApiValueInLang'

import { DataProductRequestHoursRequestT, DataSourceT } from '../../types/types'
import Flex from '../atoms/Flex'
import { SourceTypeIcons } from '../atoms/SourceTypeIcons'

type PropsT = {
  sources: DataSourceT[]
  requests: DataProductRequestHoursRequestT[]
  currentSourceId: number
  onChange: (projectId: number) => void
}

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: -14,
  },
}))

const StyledTWBadge = tw(StyledBadge)`
mr-6
`

export const ButtonGroupBadgesSources: React.FC<PropsT> = ({
  sources,
  requests,
  currentSourceId,
  onChange,
}) => {
  const { t } = useTranslation()
  const { getNazev } = useApiValueInLang()
  return (
    <>
      {sources && (
        <ButtonGroup variant='outlined' aria-label='outlined button group'>
          <Button onClick={() => onChange(0)} size='small'>
            <Flex alignItems='center' className='normal-case'>
              {t('components.all_sources')}
              <StyledTWBadge
                badgeContent={requests.length}
                color={currentSourceId === 0 ? 'primary' : 'secondary'}
              />
            </Flex>
          </Button>
          {sources.map((source) => {
            const filteredRequestsBySource = requests.filter(
              (request) => request.zdroj.id === source.id
            )
            return (
              <Button onClick={() => onChange(source.id)} key={source.id} size='small'>
                <Flex alignItems='center' className='normal-case' flexDirection='column'>
                  <SourceTypeIcons sourceType={source.typZdroje} />
                  <Flex alignItems='center'>
                    {getNazev(source)}{' '}
                    {filteredRequestsBySource.length > 0 && (
                      <StyledTWBadge
                        badgeContent={filteredRequestsBySource.length}
                        color={source.id === currentSourceId ? 'primary' : 'secondary'}
                      />
                    )}
                  </Flex>
                </Flex>
              </Button>
            )
          })}
        </ButtonGroup>
      )}
    </>
  )
}
