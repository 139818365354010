import { Divider } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'

import footerBg from '../../../assets/img/footer_bg.svg'
import logo from '../../../assets/img/logo.png'
import Container from '../../atoms/Container'
import Flex from '../../atoms/Flex'

type FooterPropsT = {
  auth: boolean | undefined
}

const StyledFooter = tw.footer`
  pt-1
  pr-0.5
  pb-1
  pl-0.5
  w-full
  bg-primary-dark
  text-secondary
`

const StyledFooterContainer = styled(Container)`
  background-image: url(${footerBg});
  background-position: center center;
`

const StyledFooterHeader = tw(Flex)`
  py-10
`

export const Footer: React.FC<FooterPropsT> = ({ auth }) => {
  const { t } = useTranslation()
  return (
    <StyledFooter>
      <StyledFooterContainer className='h-full'>
        {auth && (
          <>
            <StyledFooterHeader justifyContent='space-between' alignItems='center'>
              <Flex alignItems='center'>
                <img src={logo} alt='Logo' width={84} height={50} />
                <nav className='ml-4'>
                  <span className='text-secondary text-xl font-bold'>{t('general.portal')}</span>
                </nav>
              </Flex>
            </StyledFooterHeader>
            <Divider className='bg-secondary-dark' />
          </>
        )}
        <Flex className='py-10 text-xs' justifyContent='space-between' alignItems='center'>
          <Flex>
            <a href='https://www.cdvuz.cz/store/Obchodni_podminky_b2b.pdf'>
              <div className='text-secondary underline'>{t('footer.terms_of_use')}</div>
            </a>

            <a href='https://www.cdvuz.cz/store/Zasady_ochrany_osobnich_udaju.pdf'>
              <div className='text-secondary underline ml-6'>{t('footer.privacy')}</div>
            </a>
          </Flex>
          <div>{t('footer.copyright', { year: new Date().getFullYear() })}</div>
        </Flex>
      </StyledFooterContainer>
    </StyledFooter>
  )
}
