import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  getDataProductRequestCustomer,
  getProductRequestNoDuplicates,
} from '../../api/data/productRequest'
import { DataProjectInfoT, getDataProjectInfo } from '../../api/data/project'
import { useSelector } from '../../store/hooks'
import { DataProductRequestHoursRequestT } from '../../types/types'
import { formatDate } from '../../utils/format'
import { CurrentRequests } from '../organisms/CurrentRequests'
import { FastReservation } from '../organisms/FastReservation'
import { InfoBoxOpeningTime } from '../organisms/InfoBoxOpeningTime'
import { NearestReservations } from '../organisms/NearestReservations'
import { NewsOverview } from '../organisms/NewsOverview'
import { BaseContainer, BaseContainerItem } from '../templates/BaseContainer'

export const OverviewPage: React.FC = () => {
  const [productRequests, setProductRequests] = useState<DataProductRequestHoursRequestT[]>([])
  const [projects, setProjects] = useState<DataProjectInfoT[]>([])
  const { t } = useTranslation()

  // const isReservationOpen = useSelector((state) => state.reservation.isOpen)
  const isReservationOpen = true

  const userIcoZakaznik = useSelector((state) => state.user.icoZakaznik)

  useEffect(() => {
    Promise.all([
      getDataProductRequestCustomer(
        formatDate(dayjs().add(7, 'day').day(1), 'DD.MM.YYYY'),
        formatDate(dayjs().add(7, 'day').day(7), 'DD.MM.YYYY')
      ),
      getDataProjectInfo(),
    ]).then((values) => {
      if (values) {
        if (values[0]) {
          setProductRequests(
            getProductRequestNoDuplicates(values[0]).filter(
              (request) => request.zakaznik.ico === userIcoZakaznik
            )
          )
        }
        if (values[1]) {
          const projectsForUser = values[1].filter(
            (project) => project.zakaznik.ico === userIcoZakaznik
          )
          setProjects(projectsForUser)
        }
      }
    })
  }, [userIcoZakaznik])

  return (
    <BaseContainer>
      <InfoBoxOpeningTime
        icon={<EventAvailableOutlinedIcon fontSize='inherit' />}
        titleOnOpen={t('pages.overview_on_open')}
        titleOnClose={t('pages.overview_on_close')}
        descOnOpen={t('pages.overview_desc_on_open')}
        descOnClose={t('pages.overview_desc_on_close')}
      />
      {isReservationOpen && (
        <BaseContainerItem>
          <FastReservation />
        </BaseContainerItem>
      )}
      <BaseContainerItem>
        <CurrentRequests
          requests={productRequests}
          projects={projects}
          title={t('pages.current_requests')}
        />
      </BaseContainerItem>
      <BaseContainerItem>
        <NearestReservations
          title={t('pages.closest_reservations')}
          requests={productRequests}
          projects={projects}
        />
      </BaseContainerItem>
      <BaseContainerItem>
        <NewsOverview title={t('pages.operational_info')} />
      </BaseContainerItem>
    </BaseContainer>
  )
}
