import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'
import EventNoteIcon from '@mui/icons-material/EventNote'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import ListAltIcon from '@mui/icons-material/ListAlt'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import tw from 'tailwind-styled-components'
import { useTranslation } from 'react-i18next'

import { ROLES } from '../../constants/roles'
import { ROUTES } from '../../constants/routes'
import { useSelector } from '../../store/hooks'
import Flex from '../atoms/Flex'

type TitleProps = {
  $active?: boolean
}

const StyledSubMenuItem = tw(Flex)<TitleProps>`
  ${({ $active }) => ($active ? 'bg-primary' : '')}
  px-6
  h-full
  py-2.5
  hover:bg-primary
`

const StyledSubmenuWrapper = tw(Flex)`
  bg-primary-light
`

export const Submenu: React.FC = () => {
  const location = useLocation()
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()

  const isAdmin = useSelector(
    (state) => state.user.role === ROLES.ADMIN || state.user.role === ROLES.VUZ_SPRAVCE
  )

  return (
    <StyledSubmenuWrapper justifyContent='center' alignItems='center'>
      <Link className='no-underline text-primary-contrast' to={ROUTES(resolvedLanguage).DASHBOARD}>
        <StyledSubMenuItem
          alignItems='center'
          $active={location.pathname === ROUTES(resolvedLanguage).DASHBOARD}>
          <HomeOutlinedIcon fontSize='small' className='mr-2 text-primary-contrast' />
          {t('components.overview')}
        </StyledSubMenuItem>
      </Link>
      <Link
        className='no-underline text-primary-contrast'
        to={ROUTES(resolvedLanguage).RESERVATION}>
        <StyledSubMenuItem
          alignItems='center'
          $active={location.pathname === ROUTES(resolvedLanguage).RESERVATION}>
          <EventNoteIcon fontSize='small' className='mr-2 text-primary-contrast' />
          {t('components.reservation')}
        </StyledSubMenuItem>
      </Link>
      <Link className='no-underline text-primary-contrast' to={ROUTES(resolvedLanguage).NEWS}>
        <StyledSubMenuItem
          alignItems='center'
          $active={location.pathname === ROUTES(resolvedLanguage).NEWS}>
          <ListAltIcon fontSize='small' className='mr-2 text-primary-contrast' />
          {t('components.operation_information')}
        </StyledSubMenuItem>
      </Link>
      {isAdmin && (
        <Link
          className='no-underline text-primary-contrast'
          to={ROUTES(resolvedLanguage).ADMINISTRATION}>
          <StyledSubMenuItem
            alignItems='center'
            $active={location.pathname === ROUTES(resolvedLanguage).ADMINISTRATION}>
            <BusinessCenterOutlinedIcon fontSize='small' className='mr-2 text-primary-contrast' />
            {t('components.administration')}
          </StyledSubMenuItem>
        </Link>
      )}
    </StyledSubmenuWrapper>
  )
}
