import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'tailwind-styled-components'

import { FORMAT } from '../../../../constants/format'
import { useApiValueInLang } from '../../../../hooks/useApiValueInLang'
import { RequestDetail } from '../../../../types/types'
import { formatDateWithIncomingFormat } from '../../../../utils/format'
import { BaseButton } from '../../../atoms/BaseButton'
import { REQUEST_DETAIL_DIALOG_TABS } from './RequestDetailDialog'

interface DetailContentProps {
  setSelectedTab: (tabIndex: string) => void
  selectedRequest: RequestDetail
}

const DetailContentWrapper = tw.div`
  grid
  gap-2
  text-sm
  py-3
`

const DetailContentRow = tw.div`
  grid
  grid-cols-3
  gap-3
`

const DetailContentLeft = tw.div`
  col-span-1
`

const DetailContentRight = tw.div`
  col-span-2
`

export const DetailContent: React.FC<DetailContentProps> = ({
  setSelectedTab,
  selectedRequest,
}) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  const { getNazev } = useApiValueInLang()
  const posledniPoznamka = selectedRequest.poznamky[selectedRequest.poznamky.length - 1]
  const getRequestCreatedDate = useMemo(() => {
    return selectedRequest.historie.find((row) => row.stav.name === 'PRIJATY')?.datum
  }, [selectedRequest])

  return (
    <DetailContentWrapper>
      <DetailContentRow>
        <DetailContentLeft>{t('modals.customer')}</DetailContentLeft>
        <DetailContentRight>{`${selectedRequest.podrobnosti.zadavatel.zakaznik.nazev}`}</DetailContentRight>
      </DetailContentRow>
      <DetailContentRow>
        <DetailContentLeft>{t('modals.fixed')}</DetailContentLeft>
        <DetailContentRight>{t('modals.fixed')}</DetailContentRight>
      </DetailContentRow>
      <DetailContentRow>
        <DetailContentLeft>{t('modals.time_created')}</DetailContentLeft>
        <DetailContentRight>{`${getRequestCreatedDate}`}</DetailContentRight>
      </DetailContentRow>
      <DetailContentRow>
        <DetailContentLeft>{t('modals.project')}</DetailContentLeft>
        <DetailContentRight>{`${getNazev(
          selectedRequest.podrobnosti.projekt
        )}`}</DetailContentRight>
      </DetailContentRow>
      {/* Bude pouzivane az v druhom kroku implementacie aplikacie */}
      {/* <DetailContentRow>
        <DetailContentLeft>{t('modals.cars')}</DetailContentLeft>
        <DetailContentRight>{t('modals.locomotive')}</DetailContentRight>
      </DetailContentRow> */}
      <DetailContentRow>
        <DetailContentLeft>{t('modals.product')}</DetailContentLeft>
        <DetailContentRight>{`${getNazev(
          selectedRequest.podrobnosti.produkt
        )}`}</DetailContentRight>
      </DetailContentRow>
      <DetailContentRow>
        <DetailContentLeft>{t('modals.additional_services')}</DetailContentLeft>
        <DetailContentRight>
          <ul>
            {selectedRequest.podrobnosti.doplnkoveSluzby.map((sluzba, index) => (
              <li key={index}>{getNazev(sluzba)}</li>
            ))}
          </ul>
        </DetailContentRight>
      </DetailContentRow>
      {posledniPoznamka && (
        <DetailContentRow>
          <DetailContentLeft>{t('modals.last_note')}</DetailContentLeft>
          <DetailContentRight>
            <div className='text-xs text-gray-500'>
              {`${posledniPoznamka.uzivatel.jmeno} ${posledniPoznamka.uzivatel.prijmeni}`}{' '}
              {formatDateWithIncomingFormat(
                posledniPoznamka.datum,
                FORMAT.API_DDMMYYYY_HHMM,
                'DD. MMM., HH:mm:ss',
                resolvedLanguage
              )}
            </div>
            <div> {`${posledniPoznamka.text}`}</div>
            <BaseButton
              sx={{
                paddingLeft: 0,
              }}
              size='small'
              onClick={() => setSelectedTab(REQUEST_DETAIL_DIALOG_TABS(t)[1].name)}>
              {t('modals.read_more')}
            </BaseButton>
          </DetailContentRight>
        </DetailContentRow>
      )}
    </DetailContentWrapper>
  )
}
