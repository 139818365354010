import cs from './locales/cs/translation.json'
import en from './locales/en/translation.json'
import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { Languages } from '../types/types'

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: en,
      },
      cs: {
        translation: cs,
      },
    },
    fallbackLng: Languages.EN,

    interpolation: {
      escapeValue: false,
    },
  })
