import axios from 'axios'

import { API } from '../../constants/api'
import { LoginAuthT } from '../../types/login'
import { getFromLocalStorage } from '../../utils/localStorage'
import axiosConfig from './axiosConfig'
import i18n from 'i18next'

const { language } = i18n
const languageHeader = language ? { 'Accept-Language': language } : ({} as Record<string, string>)

export const axiosInstanceBearer = () =>
  axios.create({
    headers: {
      Authorization: `${getFromLocalStorage(API.BEARER_TOKEN)}`,
      ...languageHeader,
    },
    ...axiosConfig,
  })

export const axiosInstanceBasic = (auth: LoginAuthT) =>
  axios.create({
    auth: {
      username: auth.username,
      password: auth.password,
    },
    headers: {
      ...languageHeader,
    },
    ...axiosConfig,
  })
