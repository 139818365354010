import { alpha, styled } from '@mui/material/styles'
import { Avatar, IconButton, Menu, MenuItem, MenuProps } from '@mui/material'
import { COLORS } from '../../constants/styles'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Languages } from '../../types/types'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'

const StyledLangMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 7,
    marginTop: theme.spacing(2),
    minWidth: 55,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

export const LanguageSwitcher = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  const languages = [
    {
      locale: Languages.CS,
      title: t('languages.cs'),
    },
    {
      locale: Languages.EN,
      title: t('languages.en'),
    },
  ]
  const activeLang = languages.find((lang) => lang.locale === i18n.resolvedLanguage)
  const filteredLangs = languages.filter((lang) => lang.locale !== i18n.resolvedLanguage)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeLanguage = (locale: string) => {
    handleClose()
    i18n.changeLanguage(locale)
    navigate(ROUTES(i18n.resolvedLanguage).DASHBOARD)
    window.location.reload()
  }
  return (
    <>
      <IconButton
        id='languageSwitcher-button'
        aria-controls='languageSwitcher'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size='small'
        sx={{ ml: 1 }}>
        <Avatar
          sx={{
            bgcolor: COLORS.primary.light,
            fontSize: '0.875rem',
            fontWeight: 500,
            width: '32px',
            height: '32px',
          }}>
          {activeLang?.title}
        </Avatar>
      </IconButton>
      <StyledLangMenu
        id='languageSwitcher'
        MenuListProps={{
          'aria-labelledby': 'languageSwitcher-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {filteredLangs.map((lang) => (
          <MenuItem onClick={() => changeLanguage(lang.locale)} disableRipple key={lang.locale}>
            {lang.title}
          </MenuItem>
        ))}
      </StyledLangMenu>
    </>
  )
}
