import { yupResolver } from '@hookform/resolvers/yup'
import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { createContract, updateContract } from '../../../api/admin/contract'
import { FORMAT } from '../../../constants/format'
import { MODALS } from '../../../constants/modals'
import { useDispatch, useSelector } from '../../../store/hooks'
import { modalInitialState, modalSlice } from '../../../store/slices/modal'
import { formatDate, formatDateWithIncomingFormat } from '../../../utils/format'
import { BaseButton } from '../../atoms/BaseButton'
import { BaseLabel } from '../../atoms/BaseLabel'
import Flex from '../../atoms/Flex'
import { FieldBaseCounterInput } from '../../molecules/FieldBaseCounterInput'
import { FieldBaseInput } from '../../molecules/FieldBaseInput'
import { FieldDatePicker } from '../../molecules/FieldDatePicker'
import { Modal } from '../../molecules/Modal'

const schema = yup
  .object({
    cislo: yup.string().required(),
    popis: yup.string(),
    fixMo: yup.number().moreThan(-1),
    fixVo: yup.number().moreThan(-1),
    platnostOd: yup.string().required(),
    platnostDo: yup.string().nullable(true),
  })
  .required()

type Props = {
  onClose: () => void
  onError: (title: string, error: string) => void
}

export const CustomerContractDialog: React.FC<Props> = ({ onClose, onError }) => {
  const { modal } = useSelector((state) => state)
  const { t } = useTranslation()
  const isOpened = modal.customerContract
  const initialFormState = modal.customerContractCreatedOrChangeInitialState
  const initialFormStateEdited = {
    ...initialFormState,
    platnostOd:
      initialFormState.platnostOd &&
      formatDateWithIncomingFormat(
        initialFormState.platnostOd,
        FORMAT.API_DDMMYYYY,
        FORMAT.YYYYMMDD
      ),
    platnostDo:
      initialFormState.platnostDo &&
      formatDateWithIncomingFormat(
        initialFormState.platnostDo,
        FORMAT.API_DDMMYYYY,
        FORMAT.YYYYMMDD
      ),
  }

  const isContractUpdate = initialFormStateEdited.cislo.length > 0

  useEffect(() => {
    reset(initialFormStateEdited)
  }, [initialFormState])

  const { control, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => initialFormStateEdited, [initialFormStateEdited]),
  })

  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(
      modalSlice.actions.update({
        [MODALS.CUSTOMER_CONTRACT_CREATER_OR_CHANGE_STATE_MODAL]:
          modalInitialState.customerContractCreatedOrChangeInitialState,
        [MODALS.CUSTOMER_CONTRACT_MODAL]: false,
      })
    )
    reset()
  }

  const onSubmit = handleSubmit(async (data) => {
    if (data.platnostDo === null || data.platnostDo === 'Invalid Date') {
      delete data.platnostDo
    } else {
      data.platnostDo = formatDate(data.platnostDo, FORMAT.API_DDMMYYYY)
    }
    data.platnostOd = formatDate(data.platnostOd, FORMAT.API_DDMMYYYY)

    let response: any
    let title = ''
    if (isContractUpdate) {
      response = await updateContract(data)
      title = t('modals.failed_update_contract')
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id: _id, ...dataWithoutId } = data
      response = await createContract({ ...dataWithoutId })
      title = t('modals.failed_create_contract')
    }
    if (response && response.error) {
      onError(title, response.error)
    }

    handleClose()
    onClose()
  })

  return (
    <Modal
      type='main'
      open={isOpened}
      title={isContractUpdate ? t('modals.edit_contract') : t('modals.new_contract')}
      modalId={MODALS.CUSTOMER_CREATER_OR_CHANGE_MODAL}
      onClose={() => handleClose()}
      centered>
      <form onSubmit={onSubmit} className='w-full'>
        <div className='grid gap-5 grid-cols-2'>
          <div className='col-span-2'>
            <BaseLabel>{t('modals.contract_number')}</BaseLabel>
            <FieldBaseInput className='w-full' name='cislo' control={control} hiddenLabel />
          </div>
          <div className='col-span-2'>
            <BaseLabel>{t('modals.text_description')}</BaseLabel>
            <FieldBaseInput
              className='w-full'
              name='popis'
              multiline
              control={control}
              hiddenLabel
            />
          </div>
          <div className='col-span-1'>
            <BaseLabel>{t('modals.fixed_requirements_mzo')}</BaseLabel>
            <FieldBaseCounterInput
              name='fixMo'
              control={control}
              label={t('modals.fixed_requirements_mzo')}
              handleOnClickCounterButtons={(action, value) => {
                if (value < 0) return
                if (action === 'increase') {
                  setValue('fixMo', value + 1)
                }
                if (action === 'decrease') {
                  setValue('fixMo', value - 1)
                }
              }}
            />
          </div>
          <div className='col-span-1'>
            <BaseLabel>{t('modals.fixed_requirements_vzo')}</BaseLabel>
            <FieldBaseCounterInput
              name='fixVo'
              control={control}
              label={t('modals.fixed_requirements_vzo')}
              handleOnClickCounterButtons={(action, value) => {
                if (value < 0) return
                if (action === 'increase') {
                  setValue('fixVo', value + 1)
                }
                if (action === 'decrease') {
                  setValue('fixVo', value - 1)
                }
              }}
            />
          </div>
          <div className='col-span-1'>
            <BaseLabel>{t('modals.valid_from')}</BaseLabel>
            <FieldDatePicker name='platnostOd' hiddenLabel control={control} />
          </div>
          <div className='col-span-1'>
            <BaseLabel>{t('modals.valid_to')}</BaseLabel>
            <FieldDatePicker name='platnostDo' hiddenLabel control={control} />
          </div>
          <Flex justifyContent='end' className='col-span-2'>
            <BaseButton type='submit' variant='contained' color='primary' size='large'>
              {isContractUpdate ? t('modals.save_changes') : t('modals.create_contract')}
            </BaseButton>
          </Flex>
        </div>
      </form>
    </Modal>
  )
}
