import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import BadgeIcon from '@mui/icons-material/Badge'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Card } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { useEffect, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { getDataContractsCustomer } from '../../api/data/contracts'
import { getDataCustomerId, getDataCustomerIdUser } from '../../api/data/customer'
import { getDataProjectsCustomer, GetDataProjectsResponseT } from '../../api/data/project'
import { MODALS } from '../../constants/modals'
import { useDispatch, useSelector } from '../../store/hooks'
import { modalSlice } from '../../store/slices/modal'
import { Contract, Customer, CustomerDetailUzivatele, Uzivatel } from '../../types/types'
import { BaseButton } from '../atoms/BaseButton'
import { BaseSwitch } from '../atoms/BaseSwitch'
import Flex from '../atoms/Flex'
import { H1 } from '../atoms/Heading'
import { ImportantInfoRow, InfoRowTableCell } from '../atoms/ImportantInfoRow'
import { BaseTable, UseMemoColumn } from '../molecules/BaseTable'
import { BaseTabs, TabItem } from '../molecules/BaseTabs'
import { TransitionAlert, TransitionAlertT } from '../molecules/TransitionAlert'
import { CustomerContractDialog } from '../organisms/Modals/CustomerContractDialog'
import { CustomerProjectDialog } from '../organisms/Modals/CustomerProjectDialog'
import { BaseContainer } from '../templates/BaseContainer'
import { isAfter, isEqual } from 'date-fns'

interface CustomerDetailState {
  customer: Customer | null
  users: CustomerDetailUzivatele['uzivatele']
  contracts: Contract[]
  projects: GetDataProjectsResponseT[]
}

type TabsType = { name: 'contracts' | 'users' | 'projects'; label: string }

const TABS = (t: TFunction<'translation', undefined>): TabsType[] => [
  { name: 'contracts', label: t('pages.contracts') },
  { name: 'projects', label: t('pages.projects') },
  { name: 'users', label: t('pages.users') },
]

type ContractsTabProps = Pick<CustomerDetailState, 'contracts'> & {
  ico: Customer['ico'] | undefined
}

const TabItemHeader = tw.div`
  flex
  justify-between
  py-2
  px-4
  min-h-58
`

const TabItemSwitch = tw.div`
  flex
  items-center
  text-sm
`

const ContractsTabContent: React.FC<ContractsTabProps> = ({ contracts, ico = '' }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { modal } = useSelector((state) => state)
  const [showOnlyActive, setShowOnlyActive] = useState(false)
  const initialFormState = modal.customerContractCreatedOrChangeInitialState

  const openCustomerUpdateModal = (data: any) => {
    const updatedData = { ...data.original }
    dispatch(
      modalSlice.actions.update({
        [MODALS.CUSTOMER_CONTRACT_CREATER_OR_CHANGE_STATE_MODAL]: updatedData,
      })
    )
    dispatch(
      modalSlice.actions.update({
        [MODALS.CUSTOMER_CONTRACT_MODAL]: true,
      })
    )
  }

  const contractsTableColumns = useMemo<UseMemoColumn<Contract>[]>(
    () => [
      {
        Header: t('modals.contract_number'),
        accessor: 'cislo',
        disableFilters: true,
      },
      { Header: t('modals.description'), accessor: 'popis', disableFilters: true },
      { Header: t('pages.fixed_vzo'), accessor: 'fixVo', disableFilters: true },
      { Header: t('pages.fixed_mzo'), accessor: 'fixMo', disableFilters: true },
      { Header: t('modals.valid_from'), accessor: 'platnostOd', disableFilters: true },
      { Header: t('modals.valid_to'), accessor: 'platnostDo', disableFilters: true },
      // { Header: 'Platná', accessor: '' },
    ],
    [contracts]
  )

  const getContractsData = useCallback(() => {
    if (!showOnlyActive) {
      return contracts
    } else {
      return contracts?.filter((row) => {
        return (
          !row.platnostDo ||
          (row.platnostDo &&
            (isAfter(new Date(row.platnostDo), new Date()) ||
              isEqual(new Date(row.platnostDo), new Date())))
        )
      })
    }
  }, [contracts, showOnlyActive])

  return (
    <>
      <TabItemHeader>
        <TabItemSwitch onClick={() => setShowOnlyActive(!showOnlyActive)}>
          <BaseSwitch color='success' size='small' />
          {t('pages.active_contracts_only')}
        </TabItemSwitch>
        <>
          <BaseButton
            onClick={() => {
              openCustomerUpdateModal({ original: { ...initialFormState, icoZakaznik: ico } })
            }}
            startIcon={<AddCircleOutlineIcon />}
            color='primary'
            variant='outlined'
            size='large'>
            {t('pages.add_contract')}
          </BaseButton>
        </>
      </TabItemHeader>
      <BaseTable
        onRowClick={(data) => {
          openCustomerUpdateModal(data)
        }}
        columns={contractsTableColumns}
        data={getContractsData()}
        isSortable
      />
    </>
  )
}

type ProjectsTabProps = Pick<CustomerDetailState, 'projects'> & {
  addButtonDisabled: boolean
}

const ProjectsTabContent: React.FC<ProjectsTabProps> = ({ projects, addButtonDisabled }) => {
  const { modal } = useSelector((state) => state)
  const initialFormState = modal.customerProjectCreatedOrChangeInitialState
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const openProjectModal = (data: any) => {
    const updatedData = { ...data.original }
    dispatch(
      modalSlice.actions.update({
        [MODALS.CUSTOMER_PROJECT_CREATER_OR_CHANGE_STATE_MODAL]: updatedData,
      })
    )
    dispatch(
      modalSlice.actions.update({
        [MODALS.CUSTOMER_PROJECT_MODAL]: true,
      })
    )
  }

  const projectsTableColumns = useMemo<UseMemoColumn<GetDataProjectsResponseT>[]>(
    () => [
      {
        Header: t('modals.name'),
        accessor: 'nazevCz',
        disableFilters: true,
      },
      { Header: t('modals.description'), accessor: 'popisCz', disableFilters: true },
      { Header: t('modals.contract'), accessor: 'cisloSmlouva', disableFilters: true },
      // { Header: 'Platná', accessor: '' },
    ],
    [projects]
  )

  const projectsTableData = useMemo(() => projects, [projects])

  return (
    <>
      <TabItemHeader>
        <div />
        {/* <TabItemSwitch>
          <BaseSwitch color='success' size='small' />
          {t('pages.active_projects_only')}
        </TabItemSwitch> */}
        <>
          <BaseButton
            onClick={() => openProjectModal({ original: { ...initialFormState } })}
            startIcon={<AddCircleOutlineIcon />}
            color='primary'
            variant='outlined'
            disabled={addButtonDisabled}
            size='large'>
            {t('modals.add_project')}
          </BaseButton>
        </>
      </TabItemHeader>
      <BaseTable
        onRowClick={(data) => {
          const { original } = data

          const { nazevCz, nazevEn, popisCz, popisEn, cisloSmlouva, id } =
            original as typeof initialFormState

          const updatedData: typeof initialFormState = {
            id,
            nazevCz,
            nazevEn,
            popisCz,
            popisEn,
            cisloSmlouva,
          }

          openProjectModal({ original: updatedData })
        }}
        columns={projectsTableColumns}
        data={projectsTableData}
        isSortable
      />
    </>
  )
}

type ModifiedUser = Uzivatel & {
  jmenoPrijmeni: string
}

const UsersTabContent: React.FC<{ users: CustomerDetailState['users'] }> = ({ users }) => {
  const { t } = useTranslation()
  const modifiedUsers: ModifiedUser[] = []
  if (users.length > 0) {
    users.forEach((user) => {
      const modifiedUser = { ...user, jmenoPrijmeni: `${user.jmeno} ${user.prijmeni}` }
      modifiedUsers.push(modifiedUser)
    })
  }

  const usersTableColumns = useMemo<UseMemoColumn<typeof modifiedUsers[0]>[]>(
    () => [
      {
        Header: t('pages.name'),
        accessor: 'jmenoPrijmeni',
        disableFilters: true,
      },
      { Header: t('pages.phone'), accessor: 'telefon', disableFilters: true },
      { Header: t('pages.email'), accessor: 'email', disableFilters: true },
      { Header: t('pages.role'), accessor: 'role', disableFilters: true },
      // { Header: 'Login', accessor: "telefon" },
      // { Header: 'Aktivní', accessor: "telefon" },
    ],
    [modifiedUsers]
  )

  const usersTableData = useMemo(() => modifiedUsers, [modifiedUsers])
  return (
    <>
      <TabItemHeader>
        <TabItemSwitch>
          <BaseSwitch color='success' size='small' />
          {t('pages.active_customers_only')}
        </TabItemSwitch>
      </TabItemHeader>
      <BaseTable columns={usersTableColumns} data={usersTableData} isSortable />
    </>
  )
}

const makeImportantInfoData = (
  customer: CustomerDetailState['customer'],
  t: TFunction<'translation', undefined>
): InfoRowTableCell[] => {
  return [
    {
      title: t('modals.address'),
      icon: <LocationOnIcon />,
      info: <div>{customer?.adresa}</div>,
    },
    {
      title: t('modals.company_id'),
      icon: <BadgeIcon />,
      info: <div>{customer?.ico}</div>,
    },
    // TODO: add contact person. Currently not in api
  ]
  return []
}

export const CustomerDetailAdministrationPage: React.FC = () => {
  const [data, setData] = useState<CustomerDetailState>({
    customer: null,
    users: [],
    contracts: [],
    projects: [],
  })
  const [actionAlert, setActionAlert] = useState<TransitionAlertT>({
    title: '',
    desc: '',
    variant: 'error',
    icon: null,
  })
  const [alertOpen, setAlertOpen] = useState(false)
  const { t } = useTranslation()
  const [value, setValue] = useState(TABS(t)[0].name)
  const params = useParams()

  // const openCustomerUpdateModal = (data: any) => {
  //   const updatedData = { ...data.original }
  //   dispatch(
  //     modalSlice.actions.update({
  //       [MODALS.CUSTOMER_CREATER_OR_CHANGE_STATE_MODAL]: updatedData,
  //     })
  //   )
  //   dispatch(
  //     modalSlice.actions.update({
  //       [MODALS.CUSTOMER_CREATER_OR_CHANGE_MODAL]: true,
  //     })
  //   )
  // }

  const isModalOpened = useSelector((state) => state.modal.customerCreateOrChange)

  const loadData = async () => {
    if (params.id) {
      const response = await Promise.all([
        getDataCustomerId(params.id),
        getDataCustomerIdUser(params.id),
        getDataContractsCustomer(params.id),
        getDataProjectsCustomer(params.id),
      ])
      if (response) {
        setData({
          ...data,
          customer: response[0] || [],
          users: response[1].uzivatele || [],
          contracts: response[2] || [],
          projects: response[3] || [],
        })
      }
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  // Load data from APIs on modal close
  useEffect(() => {
    if (!isModalOpened) loadData()
  }, [isModalOpened])

  const handleError = (title: string, error: string) => {
    setActionAlert({
      title: title,
      desc: error,
      variant: 'error',
      icon: <DoNotDisturbIcon />,
    })
    setAlertOpen(true)
  }

  return (
    <BaseContainer>
      <div className='my-3'>
        <TransitionAlert
          title={actionAlert.title}
          desc={actionAlert.desc}
          icon={actionAlert.icon}
          variant={actionAlert.variant}
          open={alertOpen}
          setOpen={() => setAlertOpen(false)}
        />
      </div>
      <Flex justifyContent='space-between' alignItems='center' className='mt-7 mb-7'>
        <H1 noMargin>
          {t('modals.customer')} {data.customer?.nazev}
        </H1>
        {/* <BaseButton*/}
        {/*  onClick={() => {*/}
        {/*    openCustomerUpdateModal({ original: data.customer })*/}
        {/*  }}*/}
        {/*  variant='outlined'*/}
        {/*  size='large'*/}
        {/*  color='primary'*/}
        {/*  startIcon={<EditIcon />}>*/}
        {/*  Upravit*/}
        {/* </BaseButton>*/}
      </Flex>
      <ImportantInfoRow cells={makeImportantInfoData(data.customer, t)} />
      <BaseTabs
        value={value}
        tabs={TABS(t)}
        onChange={function (currentSourceName: string): void {
          setValue(currentSourceName as TabsType['name'])
        }}
      />
      <Card>
        <TabItem currentValue={value} tabItemValue={TABS(t)[0].name}>
          <ContractsTabContent contracts={data.contracts} ico={data.customer?.ico} />
        </TabItem>
        <TabItem currentValue={value} tabItemValue={TABS(t)[1].name}>
          <ProjectsTabContent
            projects={data.projects}
            addButtonDisabled={data.contracts.length ? false : true}
          />
        </TabItem>
        <TabItem currentValue={value} tabItemValue={TABS(t)[2].name}>
          <UsersTabContent users={data.users} />
        </TabItem>
      </Card>
      <CustomerContractDialog onClose={loadData} onError={handleError} />
      <CustomerProjectDialog
        onClose={loadData}
        onError={handleError}
        customerIco={data?.customer?.ico || ''}
      />
    </BaseContainer>
  )
}
