import { Button, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { DataNewsT, getNews } from '../../api/data/news'
import { ROUTES } from '../../constants/routes'
import { H2 } from '../atoms/Heading'
import StyledPaper from '../atoms/StyledPaper'
import { NewsPreviewPost } from '../molecules/NewsPreviewPost'

type NewsOverviewPropsT = {
  title: string
}

export const NewsOverview: React.FC<NewsOverviewPropsT> = ({ title }) => {
  const [news, setNews] = useState<DataNewsT[]>([])
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()

  useEffect(() => {
    getNews(3, 0).then((response) => setNews(response))
  }, [])
  return (
    <StyledPaper>
      <Grid container>
        <Grid item className='p-10 w-full'>
          <Grid container direction='column' spacing={0} alignItems='center'>
            <Grid>
              <H2 centered>{title}</H2>
            </Grid>
            <Grid item className='mt-3 w-full'>
              <Grid container direction='column' rowGap={5} alignItems='center'>
                {news &&
                  news.map((article) => (
                    <Grid key={article.id} container justifyContent='center'>
                      <Grid item xs={12} md={8}>
                        <NewsPreviewPost
                          id={article.id}
                          url={`${ROUTES(resolvedLanguage).NEWS_DETAIL}/${article.id}`}
                          title={article.nazev}
                          date={article.datumVytvoreni}
                          content={article.popis}
                        />
                      </Grid>
                    </Grid>
                  ))}

                <Link className='no-underline' to={ROUTES(resolvedLanguage).NEWS}>
                  <Button size='large' variant='outlined'>
                    {t('pages.all_operational_info')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledPaper>
  )
}
