import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker from '@mui/lab/DatePicker'
import { DatePickerView } from '@mui/lab/DatePicker/shared'
import czLocale from 'date-fns/locale/cs'
import enLocale from 'date-fns/locale/en-US'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { FieldError } from 'react-hook-form'
import styles from '../../constants/styles'
import { useTranslation } from 'react-i18next'

type BaseDatePickerPropsT = {
  label?: string
  value: string
  onChange: () => void
  hiddenLabel?: boolean
  error: FieldError | undefined
  openTo: DatePickerView | undefined
  views: DatePickerView[] | undefined
}

export const BaseDatePicker: React.FC<BaseDatePickerPropsT> = ({
  label,
  value,
  onChange,
  hiddenLabel,
  error,
  ...rest
}) => {
  const { i18n } = useTranslation()
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={i18n.language === 'cs' ? czLocale : enLocale}>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            sx={{
              svg: { color: styles.COLORS.primary.main, backgroundColor: styles.COLORS.grey[50] },
              backgroundColor: styles.COLORS.grey[50],
            }}
            hiddenLabel={hiddenLabel}
            className='w-full'
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: i18n.language === 'cs' ? 'dd.mm.rrrr' : 'mm/dd/yyyy',
            }}
            error={!!error}
          />
        )}
        {...rest}
      />
    </LocalizationProvider>
  )
}
