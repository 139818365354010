import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { PieChart } from 'react-minimal-pie-chart'
import tw from 'tailwind-styled-components'

import {
  getDataFixedDemandSource,
  getDataFixedDemandSourceAssigned,
} from '../../api/data/fixedDemandSource'
import { COLORS } from '../../constants/styles'
import { useSelector } from '../../store/hooks'
import { DataRequestProductT, FixedDemandSourceT, UserDetail } from '../../types/types'
import { formatDate } from '../../utils/format'

type PropsT = {
  currentCustomer: UserDetail['username']
  currentDate: Date
  currentProductName: DataRequestProductT['nazevCz'] | DataRequestProductT['nazevEn']
  projectId: number
}

const StyledContainer = tw.div`
  flex
  items-center
  px-4
  py-3
  bg-secondary-dark
`

const StyledPieChart = tw.div`
  h-9
  drop-shadow-sm
`

const StyledTextInfo = tw.div`
  ml-4
  text-sm
`

export const TimeSlotsInfoBox: React.FC<PropsT> = ({
  currentCustomer,
  currentDate,
  currentProductName,
  projectId,
}) => {
  const { t } = useTranslation()
  const isInProgress = useSelector((state) => state.reservation.isInProgress)
  const [availableFixedSlots, setAvailableFixedSlots] = useState<FixedDemandSourceT>({
    mzo: 0,
    vzo: 0,
  })
  const [usedFixedSlots, setUsedFixedSlots] = useState<FixedDemandSourceT>({
    mzo: 0,
    vzo: 0,
  })
  const totalAvailableFixedSlots = useMemo(
    () =>
      currentProductName && currentProductName === 'VZO'
        ? availableFixedSlots['vzo']
        : availableFixedSlots['mzo'],
    [availableFixedSlots]
  )
  const totalUsedFixedSlots = useMemo(
    () =>
      currentProductName && currentProductName === 'VZO'
        ? usedFixedSlots['vzo']
        : usedFixedSlots['mzo'],
    [usedFixedSlots, currentProductName]
  )
  const loadData = async () => {
    if (currentCustomer && currentDate) {
      const response = await Promise.all([
        getDataFixedDemandSource(
          currentCustomer,
          formatDate(dayjs(currentDate).day(1), 'DD.MM.YYYY'),
          formatDate(dayjs(currentDate).day(7), 'DD.MM.YYYY'),
          projectId
        ),
        getDataFixedDemandSourceAssigned(
          currentCustomer,
          formatDate(dayjs(currentDate).day(1), 'DD.MM.YYYY'),
          formatDate(dayjs(currentDate).day(7), 'DD.MM.YYYY'),
          projectId
        ),
      ]).then((values) => {
        values[0] &&
          setAvailableFixedSlots({
            vzo: values[0].vzo,
            mzo: values[0].mzo,
          })
        values[1] &&
          setUsedFixedSlots({
            vzo: values[1].vzo,
            mzo: values[1].mzo,
          })
      })
      return response
    } else {
      return null
    }
  }

  useEffect(() => {
    loadData()
  }, [currentCustomer, isInProgress, currentDate])

  return (
    <StyledContainer>
      <StyledPieChart>
        <PieChart
          startAngle={270}
          totalValue={totalAvailableFixedSlots}
          background={COLORS.secondary.main}
          data={[{ title: 'Used', value: totalUsedFixedSlots, color: COLORS.primary.main }]}
        />
      </StyledPieChart>
      <StyledTextInfo>
        <Trans t={t} i18nKey='components.fixed_requests_used'>
          Využil/a jste{' '}
          <strong>
            {{ totalUsedFixedSlots }} z {{ totalAvailableFixedSlots }} žádostí
          </strong>{' '}
          pro fixní období
        </Trans>
        <br />
        <span className='text-gray-500'>
          {t('components.fixed_requests_remaining', {
            count: totalAvailableFixedSlots - totalUsedFixedSlots,
          })}
        </span>
      </StyledTextInfo>
    </StyledContainer>
  )
}
