import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import {
  getRequestsDetail,
  NEXT_MONDAY,
  NEXT_SUNDAY,
  PREVIOUS_MONDAY,
} from '../../../../api/data/requests'
import { MODALS } from '../../../../constants/modals'
import { ROUTES } from '../../../../constants/routes'
import styles from '../../../../constants/styles'
import { GetNazevT, useApiValueInLang } from '../../../../hooks/useApiValueInLang'
import { useDispatch, useSelector } from '../../../../store/hooks'
import { modalSlice } from '../../../../store/slices/modal'
import { DataRequestT, DataSourceT, RequestDetailWithTimeSlots } from '../../../../types/types'
import { H4 } from '../../../atoms/Heading'
import { SelectWithArrowButton } from '../../../atoms/SelectWithArrowButtons'
import { Modal } from '../../../molecules/Modal'
import { DayItems } from './DayItems'
import { RequestDetailInfo } from './RequestDetailInfo'

export type SelectedDetailSlotT = {
  day: number
  slot: number
}

const ModalWrapper = tw.div`
  grid
  grid-cols-3
`

const ModalLeftCol = tw.div`
  col-span-1
  bg-gray-50
  border-r
  border-gray-200
`

const ModalRightCol = tw.div`
  col-span-2
`

const ModalLeftColHeader = tw.div`
  border-b
  border-gray-200
  pt-8
  pb-3
  px-7
`

const ModalRightColHeader = tw.div`
  pt-8
  px-7
`

export const REQUEST_DETAIL_DIALOG_TABS = (t: TFunction<'translation', undefined>) => [
  { name: 'details', label: t('modals.tab_details') },
  { name: 'notes', label: t('modals.tab_notes') },
  { name: 'history', label: t('modals.tab_history') },
]

const SELECTED_SLOT_INIT_STATE = {
  day: 0,
  slot: 0,
}

const filterRequests = (
  request: RequestDetailWithTimeSlots['casoveSloty'][0],
  currentFilter: DataSourceT['nazevCz'] | DataSourceT['nazevEn'],
  requestState: DataRequestT['stav']['name'],
  getNazev: GetNazevT
) =>
  currentFilter
    ? request.zadosti.some(
        (zadost) => zadost.stav.name === requestState && getNazev(zadost.zdroj) === currentFilter
      )
    : request.zadosti.some((zadost) => zadost.stav.name === requestState)

type RequestDetailDialogT = {
  requestState?: DataRequestT['stav']['name']
  currentFilter: string
}

export const RequestDetailDialog: React.FC<RequestDetailDialogT> = ({
  requestState = 'PRIJATY',
  currentFilter,
}) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  const { getNazev } = useApiValueInLang()
  const dispatch = useDispatch()
  const { requestDetailId } = useSelector((state) => state.modal)
  const isOpened = useSelector((state) => state.modal.requestDetail)

  const [timeSlotsWithRequests, setTimeSlotsWithRequests] = useState<RequestDetailWithTimeSlots[]>(
    []
  )
  const [selectedSlotIndex, setSelectedSlotIndex] =
    useState<SelectedDetailSlotT>(SELECTED_SLOT_INIT_STATE)
  const [selectedRequestId, setSelectedRequestId] = useState<number>(requestDetailId)

  const rejectRequestIsSending = useSelector((state) => !state.modal.rejectRequestIsSending)
  const acceptRequestIsSending = useSelector((state) => !state.modal.acceptRequestIsSending)

  const location = useLocation()

  useEffect(() => {
    setSelectedSlotIndex(SELECTED_SLOT_INIT_STATE)
  }, [rejectRequestIsSending, acceptRequestIsSending])

  useEffect(() => {
    if (location.pathname === ROUTES(resolvedLanguage).RESERVATION) {
      // Hide modal after confirmation or page load
      dispatch(
        modalSlice.actions.update({
          [MODALS.REQUEST_DETAIL_MODAL]: false,
          [MODALS.REQUEST_DETAIL_ID]: 0,
        })
      )

      getRequestsDetail(
        requestState === 'PRIJATY' ? NEXT_MONDAY : PREVIOUS_MONDAY,
        NEXT_SUNDAY
      ).then((detail) => {
        const filteredDays = detail.filter((day) =>
          day.casoveSloty.some((request) =>
            filterRequests(request, currentFilter, requestState, getNazev)
          )
        )

        const filteredDaysSlots = filteredDays.reduce((acc, day) => {
          const filteredDaySlot = {
            ...day,
            casoveSloty: day.casoveSloty.filter((request) =>
              filterRequests(request, currentFilter, requestState, getNazev)
            ),
          } as RequestDetailWithTimeSlots

          const filteredSlotsRequests = filteredDaySlot.casoveSloty.reduce((acc, slot) => {
            const filteredSlot = {
              ...slot,
              zadosti: currentFilter
                ? slot.zadosti.filter(
                    (request) =>
                      request.stav.name === requestState &&
                      getNazev(request.zdroj) === currentFilter
                  )
                : slot.zadosti.filter((request) => request.stav.name === requestState),
            } as RequestDetailWithTimeSlots['casoveSloty'][0]
            return [...(acc as RequestDetailWithTimeSlots['casoveSloty']), filteredSlot]
          }, [] as any)

          return [
            ...acc,
            { ...day, casoveSloty: filteredSlotsRequests },
          ] as RequestDetailWithTimeSlots[]
        }, [] as RequestDetailWithTimeSlots[])

        detail && detail.length > 0 && setTimeSlotsWithRequests(filteredDaysSlots)
      })
    }
  }, [rejectRequestIsSending, acceptRequestIsSending, location, currentFilter, requestState])

  const selectedTimeslot = useMemo(() => {
    return timeSlotsWithRequests[selectedSlotIndex.day]?.casoveSloty[selectedSlotIndex.slot]
  }, [timeSlotsWithRequests, selectedSlotIndex])

  const selectedRequest = useMemo(
    () => selectedTimeslot?.zadosti.find((request) => request.id === selectedRequestId),
    [selectedTimeslot, selectedRequestId]
  )

  useEffect(() => {
    setSelectedRequestId(requestDetailId)
  }, [requestDetailId])

  useEffect(() => {
    const dayIndex = timeSlotsWithRequests.findIndex((days) =>
      days.casoveSloty.some((slot) =>
        slot.zadosti.some((zadost) => zadost.id === selectedRequestId)
      )
    )

    if (!timeSlotsWithRequests[dayIndex]) {
      return
    }

    const slotIndex = timeSlotsWithRequests[dayIndex].casoveSloty.findIndex((slot) =>
      slot.zadosti.some((zadost) => zadost.id === selectedRequestId)
    )

    if (!timeSlotsWithRequests[dayIndex].casoveSloty[slotIndex]) {
      return
    }
    setSelectedSlotIndex({ day: dayIndex, slot: slotIndex })
  }, [timeSlotsWithRequests, selectedRequestId])

  const handleClose = () => {
    dispatch(
      modalSlice.actions.update({
        [MODALS.REQUEST_DETAIL_MODAL]: false,
        [MODALS.REQUEST_DETAIL_ID]: 0,
      })
    )
  }

  const createSelectData = (selectedTimeslot: RequestDetailWithTimeSlots['casoveSloty'][0]) => {
    return selectedTimeslot.zadosti.map((zadost, index) => ({
      id: zadost.id,
      name: zadost.podrobnosti.zadavatel.zakaznik.nazev,
      index,
    }))
  }

  if (timeSlotsWithRequests && timeSlotsWithRequests.length > 0) {
    return (
      <Modal
        type='noPadding'
        open={isOpened}
        modalId={MODALS.REQUEST_DETAIL_MODAL}
        onClose={handleClose}
        maxWidth='lg'>
        <ModalWrapper>
          <ModalLeftCol>
            <ModalLeftColHeader>
              <H4>{getNazev(selectedTimeslot?.zadosti[0].zdroj)}</H4>
              <Link
                to={ROUTES(resolvedLanguage).DASHBOARD}
                className='text-xs inline-flex text-primary'>
                <OpenInNewIcon
                  sx={{
                    fontSize: '0.875rem',
                    marginRight: '0.25rem',
                    color: styles.COLORS.primary.main,
                  }}
                />
                <div>{t('modals.full_time_plan')}</div>
              </Link>
            </ModalLeftColHeader>
            <DayItems
              timeSlotsWithRequests={timeSlotsWithRequests}
              selectedSlotIndex={selectedSlotIndex}
              setSelectedSlotIndex={setSelectedSlotIndex}
              setSelectedRequestId={setSelectedRequestId}
            />
          </ModalLeftCol>
          <ModalRightCol>
            <ModalRightColHeader>
              <H4>
                {t('modals.requests_for_slot')}{' '}
                {getNazev(selectedTimeslot?.zadosti[0].podrobnosti.produkt)}{' '}
                {selectedTimeslot?.termin.terminOd}–{selectedTimeslot?.termin.terminDo}
              </H4>
              <div className='my-4'>
                <SelectWithArrowButton
                  data={selectedTimeslot && createSelectData(selectedTimeslot)}
                  value={selectedRequestId}
                  onChange={setSelectedRequestId}
                />
              </div>
            </ModalRightColHeader>

            {selectedRequest && (
              <RequestDetailInfo
                selectedRequest={selectedRequest}
                selectedRequestId={selectedRequestId}
              />
            )}
          </ModalRightCol>
        </ModalWrapper>
      </Modal>
    )
  }
  return <></>
}
