import { GetDataSourceBySourceTypeT, GetDataSourceTypeAllT } from './../api/data/source'
import { AdditionalServicesT, GetDataProductBySourceSourceT } from './../api/data/product'
import { GetDataAdditionalServiceT } from './../api/data/additionalService'
import { ProjectData } from './../api/admin/project'
import { useTranslation } from 'react-i18next'
import { GetDataProjectsResponseT } from '../api/data/project'
import { DataSourceT, Languages, Request } from '../types/types'

type NameArgs =
  | ProjectData
  | GetDataAdditionalServiceT
  | AdditionalServicesT
  | GetDataProductBySourceSourceT
  | GetDataProjectsResponseT
  | GetDataSourceBySourceTypeT
  | Request['zdroj']
  | Pick<DataSourceT, 'nazevCz' | 'nazevEn'>

type MeaningArgs = GetDataProductBySourceSourceT['typZdroje'] | GetDataSourceTypeAllT

export type GetNazevT = (data: NameArgs) => string
export type GetVyznamT = (data: MeaningArgs) => string

export const useApiValueInLang = () => {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation()

  const getNazev: GetNazevT = (data: NameArgs) => {
    return resolvedLanguage === Languages.CS ? data.nazevCz : data.nazevEn
  }

  const getVyznam: GetVyznamT = (data: MeaningArgs) => {
    return resolvedLanguage === Languages.CS ? data.vyznamCz : data.vyznamEn
  }

  const getNazevString = () => (resolvedLanguage === Languages.CS ? 'nazevCz' : 'nazevEn')
  const getPopisString = () => (resolvedLanguage === Languages.CS ? 'popisCz' : 'popisEn')

  return {
    getNazev,
    getVyznam,
    getNazevString,
    getPopisString,
  }
}
