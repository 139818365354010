import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import DirectionsTransitFilledIcon from '@mui/icons-material/DirectionsTransitFilled'
import DoneIcon from '@mui/icons-material/Done'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import HomeIcon from '@mui/icons-material/Home'
import { IconButton, styled } from '@mui/material'
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { Row } from 'react-table'

import {
  confirmRequestList,
  confirmRequestListT,
  rejectRequestList,
} from '../../api/action/specifyRequest'
import { getRequestsHistory } from '../../api/data/requests'
import { FORMAT } from '../../constants/format'
import { MODALS } from '../../constants/modals'
import styles, { COLORS } from '../../constants/styles'
import { useApiValueInLang, GetNazevT } from '../../hooks/useApiValueInLang'
import { useSnackbar } from '../../hooks/useSnackbar'
import { useDispatch } from '../../store/hooks'
import { modalSlice } from '../../store/slices/modal'
import { snackBarSlice } from '../../store/slices/snackBar'
import { Request } from '../../types/types'
import { formatDateWithIncomingFormat } from '../../utils/format'
import { BaseButton } from '../atoms/BaseButton'
import Flex from '../atoms/Flex'
import TableWrapper from '../atoms/TableWrapper'
import { BaseTable } from '../molecules/BaseTable'

const StickyFooter = styled('div')(() => ({
  position: 'sticky',
  bottom: 0,
  width: '100%',
  minHeight: '56',
  background: COLORS.primary.dark,
  color: COLORS.primary.contrastText,
  padding: '20px',
}))

const renderCreationDate = (rowItem: Request, locale: string) => {
  return formatDateWithIncomingFormat(
    rowItem.vytvoreni,
    FORMAT.API_DDMMYYYY_HHMM,
    'DD. MMM., HH:mm:ss',
    locale
  )
}

const renderContractor = (rowItem: Request) => {
  return (
    <>
      <span>{`${rowItem.zadavatel.zakaznik.nazev ?? ''} `}</span>
      <span>{`(${rowItem.zadavatel.uzivatel.jmeno ?? ''} ${
        rowItem.zadavatel.uzivatel.prijmeni ?? ''
      })`}</span>
    </>
  )
}

const renderFix = (rowItem: Request) => {
  if (rowItem.fixni) return <CheckCircleIcon color='primary' sx={{ fontSize: '1.25rem' }} />

  return <HighlightOffIcon sx={{ color: styles.COLORS.grey[400], fontSize: '1.25rem' }} />
}

const renderSource = (rowItem: Request, getNazev: GetNazevT) => {
  const nazev = getNazev(rowItem.zdroj)
  let source: Request['zdroj']['nazevCz'] | Request['zdroj']['nazevEn'] | ReactElement = nazev
  if (nazev === 'VZO' || nazev === 'MZO') {
    source = (
      <>
        <Flex alignItems='center'>
          <DirectionsTransitFilledIcon sx={{ fontSize: '1.25rem', marginRight: '0.25rem' }} />
          {nazev}
        </Flex>
      </>
    )
  } else {
    source = (
      <>
        <Flex alignItems='center'>
          <HomeIcon sx={{ fontSize: '1.25rem', marginRight: '0.25rem' }} />
          {nazev}
        </Flex>
      </>
    )
  }
  return source
}

const renderTermin = (rowItem: Request, t: TFunction<'translation', undefined>, locale: string) => {
  const formatedDate = `${formatDateWithIncomingFormat(
    rowItem.termin.terminOd,
    FORMAT.API_DDMMYYYY_HHMM,
    'DD. MMM., HH:mm',
    locale
  )} - ${formatDateWithIncomingFormat(
    rowItem.termin.terminDo,
    FORMAT.API_DDMMYYYY_HHMM,
    'HH:mm',
    locale
  )}`
  if (rowItem.terminObsazeny)
    return (
      <>
        <div>{formatedDate}</div>
        <div>{t('pages.full')}</div>
      </>
    )

  return formatedDate
}

interface Test {
  tableData: Request[]
  loadData: () => Promise<void>
}

const HandleReservationTable: React.FC<Test> = ({ tableData, loadData }) => {
  const dispatch = useDispatch()
  const [displaySnackBarWithMessage] = useSnackbar()
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  const { getNazev } = useApiValueInLang()

  const openDetailModal = (row: Row<Object>) => {
    dispatch(
      modalSlice.actions.update({
        // @ts-ignore
        [MODALS.REQUEST_DETAIL_ID]: row.original.id,
        [MODALS.REQUEST_DETAIL_MODAL]: true,
      })
    )
  }

  const [selectedFlatRows, setSelectedFlatRows] = useState<Row<object>[]>([])
  const ref = useRef<{ toggleAllRowsSelected: (value?: boolean | undefined) => void }>({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    toggleAllRowsSelected: (value?: boolean | undefined) => null,
  })

  useEffect(() => {
    return () => {
      snackBarSlice.actions.reset()
    }
  }, [])

  const rejectRequests = async (idArray: confirmRequestListT[]) => {
    const response = await rejectRequestList(idArray)
    const multipleOrOneId = idArray.length === 1 ? t('modals.request') : t('modals.all_requests')
    if (response.some((res) => 'error' in res)) {
      displaySnackBarWithMessage({
        message: t('modals.failed_to_reject', { multipleOrOneId }),
        isOpen: true,
        severity: 'error',
      })
    } else {
      displaySnackBarWithMessage({
        message: t('modals.success_to_reject', { multipleOrOneId }),
        isOpen: true,
        severity: 'success',
      })
    }
    loadData()
  }

  const acceptRequests = async (idArray: confirmRequestListT[]) => {
    const response = await confirmRequestList(idArray)
    const multipleOrOneId = idArray.length === 1 ? t('modals.request') : t('modals.all_requests')
    if (response.some(() => 'error' in response)) {
      displaySnackBarWithMessage({
        message: t('modals.failed_to_accept', { multipleOrOneId }),
        isOpen: true,
        severity: 'error',
      })
    } else {
      displaySnackBarWithMessage({
        message: t('modals.success_to_accept', { multipleOrOneId }),
        isOpen: true,
        severity: 'success',
      })
      loadData()
    }
  }

  const data = useMemo(() => tableData, [tableData])
  const columns = useMemo(
    () => [
      {
        Header: t('pages.source'),
        accessor: 'zdroj',
        // @ts-ignore
        Cell: ({ row }) => {
          return renderSource(row.original, getNazev)
        },
        // Filter: (props: any) => {
        //   return (
        //     <AutocompleteColumnFilter
        //       autoCompleteProps={{
        //         options: testOptions,
        //         inputlabel: 'test',
        //         onChange: (event) => console.log(event, 'event'),
        //         renderOption: (props, option, state) => {
        //           // console.log(props, option, state)
        //           return (
        //             <Box component='li' key={props.id} {...props}>
        //               {option.icon} {`${option.label}`}
        //             </Box>
        //           )
        //         },
        //       }}
        //     />
        //   )
        // },
        // filter: 'equals',
        disableFilters: true,
      },
      {
        Header: t('pages.date'),
        accessor: 'termin.terminOd',
        // @ts-ignore
        Cell: ({ row }) => {
          return renderTermin(row.original, t, resolvedLanguage)
        },
        disableFilters: true,
      },
      {
        Header: t('modals.fixed'),
        accessor: 'fixni',
        // @ts-ignore
        Cell: ({ row }) => {
          return renderFix(row.original)
        },
        disableFilters: true,
      },
      {
        Header: t('pages.client'),
        accessor: 'zadavatel.zakaznik.nazev',
        // @ts-ignore
        Cell: ({ row }) => {
          return renderContractor(row.original)
        },
        disableFilters: true,
      },
      {
        Header: t('modals.additional_services'),
        accessor: 'doplnkoveSluzby',
        disableFilters: true,
      },
      {
        Header: t('pages.create_date'),
        accessor: 'vytvoreni',
        // @ts-ignore
        Cell: ({ row }) => {
          return renderCreationDate(row.original, resolvedLanguage)
        },
        disableFilters: true,
      },
    ],
    [tableData]
  )

  const closeStickyFooter = () => {
    setSelectedFlatRows([])
    ref.current.toggleAllRowsSelected(false)
  }

  const someSourceIsOccupied = useMemo(() => {
    return selectedFlatRows.some((row) => {
      const original = row.original as Request
      return !!original.terminObsazeny
    })
  }, [selectedFlatRows])

  const selectedRowsIds = useMemo(() => {
    return selectedFlatRows.map((row) => {
      const original = row.original as Request
      return { id: original.id, poznamka: '' }
    })
  }, [selectedFlatRows])

  return (
    <>
      <BaseTable
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSelectedRowsChange={(selectedFlatRows, _selectedRowIds) => {
          setSelectedFlatRows(selectedFlatRows)
        }}
        onRowClick={openDetailModal}
        isSortable
        columns={columns}
        data={data}
        ref={ref}
      />
      {selectedFlatRows.length > 0 && (
        <StickyFooter>
          <Flex justifyContent='space-between' alignItems='center'>
            <Flex alignItems='center'>
              <IconButton color='secondary' onClick={() => closeStickyFooter()}>
                <CloseIcon />
              </IconButton>
              {t('pages.items', { count: selectedFlatRows.length })}
            </Flex>
            <Flex alignItems='center'>
              {someSourceIsOccupied && <div>{t('pages.at_least_one_busy')}</div>}
              <BaseButton
                color='secondary'
                disabled={someSourceIsOccupied}
                onClick={async () => {
                  acceptRequests(selectedRowsIds)
                  closeStickyFooter()
                }}
                startIcon={<DoneIcon />}>
                {t('modals.agree')}
              </BaseButton>
              <BaseButton
                color='secondary'
                onClick={async () => {
                  rejectRequests(selectedRowsIds)
                  closeStickyFooter()
                }}
                startIcon={<CloseIcon />}>
                {t('modals.reject')}
              </BaseButton>
            </Flex>
          </Flex>
        </StickyFooter>
      )}
    </>
  )
}

interface tableData {
  requestTable: Request[]
}

export const RequestsHistoryTable: React.FC = () => {
  const [tableData, setTableData] = useState<tableData>({ requestTable: [] })

  const loadData = async () => {
    const response = await getRequestsHistory()
    const filteredResponse = response.filter((request) => request.stav.name === 'POTVRZENY')
    setTableData({ ...tableData, requestTable: filteredResponse })
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <>
      <TableWrapper>
        <HandleReservationTable loadData={loadData} tableData={tableData.requestTable} />
      </TableWrapper>
    </>
  )
}
