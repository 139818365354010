import { Languages } from '../types/types'

type GetRoutesArgs = {
  locale: string
  withLocaleString?: boolean
}

export const getRoutes = ({ locale, withLocaleString }: GetRoutesArgs) => {
  switch (locale) {
    case Languages.CS:
      const localeStringCS = withLocaleString ? 'cs' : ':locale'
      return {
        DASHBOARD: `/${localeStringCS}`,
        LOGIN: `/${localeStringCS}/prihlaseni`,
        RESET_PASSWORD: `/${localeStringCS}/reset-hesla`,
        ADMINISTRATION: `/${localeStringCS}/administrace`,
        ADMINISTRATION_CUSTOMER_DETAIL: `/${localeStringCS}/administrace`,
        RESERVATION: `/${localeStringCS}/rezervace`,
        CREATE_RESERVATION: `/${localeStringCS}/vytvorit-rezervaci`,
        NEWS: `/${localeStringCS}/provozni-informace`,
        NEWS_DETAIL: `/${localeStringCS}/provozni-informace`,
      }
    default:
      const localeStringEN = withLocaleString ? 'en' : ':locale'
      return {
        DASHBOARD: `/${localeStringEN}`,
        LOGIN: `/${localeStringEN}/login`,
        RESET_PASSWORD: `/${localeStringEN}/reset-password`,
        ADMINISTRATION: `/${localeStringEN}/administration`,
        ADMINISTRATION_CUSTOMER_DETAIL: `/${localeStringEN}/administration`,
        RESERVATION: `/${localeStringEN}/reservation`,
        CREATE_RESERVATION: `/${localeStringEN}/create-reservation`,
        NEWS: `/${localeStringEN}/operational-information`,
        NEWS_DETAIL: `/${localeStringEN}/operational-information`,
      }
  }
}
export const ROUTES_FOR_ROUTER = (locale: string) => getRoutes({ locale })
export const ROUTES = (locale: string) => getRoutes({ locale, withLocaleString: true })
