import './assets/styles/global.css'

import React, { useEffect } from 'react'
import { Navigate, useLocation, useRoutes, useParams } from 'react-router-dom'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { AdministrationPage } from './components/pages/AdministrationPage'
import { CreateReservationPage } from './components/pages/CreateReservationPage'
import { CustomerDetailAdministrationPage } from './components/pages/CustomerDetailAdministrationPage'
import { LoginPage } from './components/pages/LoginPage'
import { NewsDetailPage } from './components/pages/NewsDetailPage'
import { NewsPage } from './components/pages/NewsPage'
import { OverviewPage } from './components/pages/OverviewPage'
import { OverviewPageAdmin } from './components/pages/OverviewPageAdmin'
import { ReservationPage } from './components/pages/ReservationPage'
import { ResetPasswordPage } from './components/pages/ResetPasswordPage'
import { Layout } from './components/templates/Layout'
import { API } from './constants/api'
import { APP } from './constants/app'
import { ROLES } from './constants/roles'
import { ROUTES, ROUTES_FOR_ROUTER } from './constants/routes'
import { useLogout } from './hooks/useLogout'
import { useSelector } from './store/hooks'
import { getFromLocalStorage } from './utils/localStorage'
import { useTranslation } from 'react-i18next'
import { Languages } from './types/types'

type RequireAuthT = {
  children: React.ReactElement
}

function RequireAuth({ children }: RequireAuthT) {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation()
  const isAuthenticated =
    useSelector((state) => state.user.logged) && !!getFromLocalStorage(API.BEARER_TOKEN)
  return isAuthenticated ? children : <Navigate to={ROUTES(resolvedLanguage).LOGIN} />
}

const PickLanguage = () => {
  const { locale } = useParams()
  const {
    i18n: { changeLanguage, resolvedLanguage },
  } = useTranslation()

  const isValidLocale = (Object.values(Languages) as string[]).includes(locale ?? '')

  if (resolvedLanguage !== locale && isValidLocale) {
    changeLanguage(locale)
    window.location.reload()
  }

  return null
}

export const useRoutesObject = () => {
  const { t } = useTranslation()
  const isAuthenticated =
    useSelector((state) => state.user.logged) && !!getFromLocalStorage(API.BEARER_TOKEN)
  const isAdmin = useSelector(
    (state) => state.user.role === ROLES.ADMIN || state.user.role === ROLES.VUZ_SPRAVCE
  )
  const isEditor = useSelector((state) => state.user.role === ROLES.VUZ_EDITOR)

  const createRoutesObject = (locale: Languages): BreadcrumbsRoute[] => [
    {
      path: ROUTES_FOR_ROUTER(locale).DASHBOARD,
      element: <Layout auth={isAuthenticated} />,
      children: [
        {
          breadcrumb: t('pages.dashboard_breadcrumb'),
          index: true,
          element: (
            <>
              <PickLanguage />
              <RequireAuth>
                {isAdmin || isEditor ? <OverviewPageAdmin /> : <OverviewPage />}
              </RequireAuth>
            </>
          ),
        },
        {
          path: ROUTES_FOR_ROUTER(locale).LOGIN,
          element: !isAuthenticated ? (
            <>
              <PickLanguage />
              <LoginPage />
            </>
          ) : (
            <Navigate to={ROUTES(locale).DASHBOARD} />
          ),
        },
        {
          path: ROUTES_FOR_ROUTER(locale).RESET_PASSWORD,
          element: (
            <>
              <PickLanguage />
              <ResetPasswordPage />
            </>
          ),
        },
        {
          path: ROUTES_FOR_ROUTER(locale).RESERVATION,
          element: (
            <>
              <PickLanguage />
              <RequireAuth>
                {isAdmin || isEditor ? <ReservationPage /> : <CreateReservationPage />}
              </RequireAuth>
            </>
          ),
        },
        {
          path: ROUTES_FOR_ROUTER(locale).CREATE_RESERVATION,
          element: (
            <>
              <PickLanguage />
              <RequireAuth>
                <CreateReservationPage />
              </RequireAuth>
            </>
          ),
        },
        {
          path: `${ROUTES_FOR_ROUTER(locale).CREATE_RESERVATION}/:projectId`,
          element: (
            <>
              <PickLanguage />
              <RequireAuth>
                <CreateReservationPage />
              </RequireAuth>
            </>
          ),
        },
        // Possible child component ?
        {
          path: `${ROUTES_FOR_ROUTER(locale).RESERVATION}/:projectId`,
          element: (
            <>
              <PickLanguage />
              <RequireAuth>
                <ReservationPage />
              </RequireAuth>
            </>
          ),
        },
        {
          path: ROUTES_FOR_ROUTER(locale).NEWS,
          element: (
            <>
              <PickLanguage />
              <RequireAuth>
                <NewsPage />
              </RequireAuth>
            </>
          ),
        },
        {
          path: `${ROUTES_FOR_ROUTER(locale).NEWS_DETAIL}/:articleId`,
          element: (
            <>
              <PickLanguage />
              <RequireAuth>
                <NewsDetailPage />
              </RequireAuth>
            </>
          ),
        },
        {
          path: ROUTES_FOR_ROUTER(locale).ADMINISTRATION,
          breadcrumb: t('pages.admin_breadcrumb'),
          element: isAdmin && (
            <>
              <PickLanguage />
              <RequireAuth>
                <AdministrationPage />
              </RequireAuth>
            </>
          ),
        },
        {
          path: `${ROUTES_FOR_ROUTER(locale).ADMINISTRATION_CUSTOMER_DETAIL}/:id`,
          breadcrumb: t('pages.customer_detail_breadcrumb'),
          element: (
            <>
              <PickLanguage />
              <RequireAuth>
                <CustomerDetailAdministrationPage />
              </RequireAuth>
            </>
          ),
        },
        {
          path: '*',
          element: <Navigate to={ROUTES(locale).DASHBOARD} />,
        },
      ],
    },
    {
      path: '/',
      element: <Navigate to={ROUTES(locale).DASHBOARD} />,
      breadcrumb: null,
    },
  ]
  const routesObject = [...createRoutesObject(Languages.CS), ...createRoutesObject(Languages.EN)]

  return routesObject
}

export const App: React.FC = () => {
  // Logout user after inactivity
  const location = useLocation()
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()

  useEffect(() => {
    let time: any
    if (location.pathname !== ROUTES(resolvedLanguage).LOGIN) {
      const resetTimer = () => {
        clearTimeout(time)
        time = setTimeout(logout, APP.CONFIG.USER_INACTIVITY)
      }
      const logout = () => {
        alert(t('pages.logged_out'))
        useLogout()
      }
      window.onload = resetTimer
      document.onmousemove = resetTimer
      document.onkeydown = resetTimer
    } else {
      window.onload = null
      document.onmousemove = null
      document.onkeydown = null
    }
    return () => clearTimeout(time)
  }, [location])

  const routesObject = useRoutesObject()
  const element = useRoutes(routesObject)

  return <div>{element}</div>
}
