import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'
import tw from 'tailwind-styled-components'

import { FORMAT } from '../../constants/format'
import { REQUEST } from '../../constants/request'
import { useApiValueInLang } from '../../hooks/useApiValueInLang'
import { DataProductRequestHoursRequestT, DataSourceT } from '../../types/types'
import { formatDateWithIncomingFormat } from '../../utils/format'
import Flex from '../atoms/Flex'
import { H2 } from '../atoms/Heading'
import { SourceTypeIcons } from '../atoms/SourceTypeIcons'
import StyledPaper from '../atoms/StyledPaper'
import { BaseTable } from '../molecules/BaseTable'
import { ButtonGroupBadgesSources } from '../molecules/ButtonGroupBadgesSources'

type PropsT = {
  title: string
  requests: DataProductRequestHoursRequestT[]
  sources: DataSourceT[]
}

const StyledTable = tw.div`
  mt-6
`

export const AgreedRequests: React.FC<PropsT> = ({ title, requests, sources }) => {
  const [currentSourceId, setCurrentSourceId] = useState<number>(0)
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  const { getNazev } = useApiValueInLang()

  const requestsTableColumns = React.useMemo(
    () => [
      {
        Header: t('pages.last_activity'),
        accessor: 'time',
        disableFilters: true,
        sortType: (a: Row<any>, b: Row<any>) => {
          const rowA = new Date(a.original.timeRaw).getTime()
          const rowB = new Date(b.original.timeRaw).getTime()
          if (rowA > rowB) return -1
          if (rowB > rowA) return 1
          return 0
        },
      },
      {
        Header: t('pages.source'),
        accessor: 'source',
        disableFilters: true,
        sortType: (a: Row<any>, b: Row<any>) => {
          if (a.original.sourceRaw > b.original.sourceRaw) return -1
          if (b.original.sourceRaw > a.original.sourceRaw) return 1
          return 0
        },
      },
      {
        Header: t('pages.date'),
        accessor: 'shift',
        disableFilters: true,
      },
      {
        Header: t('pages.client'),
        accessor: 'company',
        disableFilters: true,
      },
      {
        Header: t('pages.project'),
        accessor: 'project',
        disableFilters: true,
      },
      {
        Header: t('modals.additional_services'),
        accessor: 'additional_services',
        disableFilters: true,
      },
      // {
      //   Header: 'Akce',
      //   accessor: 'action',
      //   // @ts-ignore
      //   Cell: ({ row }) => {
      //     console.log('row.original', row.original.action)
      //     return (
      //       <Link to={ROUTES.RESERVATION}>
      //         <CommentIcon
      //           onClick={() => {
      //             dispatch(
      //               modalSlice.actions.update({
      //                 requestDetail: true,
      //                 requestDetailId: row.original.action,
      //               })
      //             )
      //           }}
      //           fontSize='small'
      //         />
      //       </Link>
      //     )
      //   },
      //   disableFilters: true,
      // },
    ],
    []
  )

  const acceptedRequests = React.useMemo(
    () => requests.filter((request) => request.pozadavek.stav.kod === REQUEST.STATE.CONFIRMED),
    [requests]
  )

  const requestsTableData = React.useMemo(() => {
    const filteredRequests =
      currentSourceId > 0
        ? acceptedRequests.filter((request) => request.zdroj.id === currentSourceId)
        : acceptedRequests
    return filteredRequests.map((request) => ({
      time: (
        <>
          {formatDateWithIncomingFormat(
            request.pozadavek.datumZadavatel,
            FORMAT.API_DDMMYYYY_HHMM,
            'DD. MMM., HH:mm',
            resolvedLanguage
          )}
        </>
      ),
      timeRaw: request.pozadavek.datumZadavatel,
      source: (
        <Flex alignItems='center'>
          <SourceTypeIcons fontSize='small' sourceType={request.zdroj.typZdroje} />{' '}
          <div className='ml-2'>{getNazev(request.zdroj)}</div>
        </Flex>
      ),
      sourceRaw: getNazev(request.zdroj),
      shift: `${formatDateWithIncomingFormat(
        request.pozadavek.datum,
        FORMAT.API_DDMMYYYY,
        'DD. MMM.',
        resolvedLanguage
      )}, ${request.pozadavek.zadani.dny[0].hodinaOd}-${request.pozadavek.zadani.dny[0].hodinaDo}`,
      company: request.zakaznik.nazev,
      project: getNazev(request.projekt),
      additional_services: request.doplnkoveSluzby ? request.doplnkoveSluzby.length : null,
      // action: request.pozadavek.id,
    }))
  }, [requests, currentSourceId])

  return (
    <StyledPaper>
      <H2 centered>{title}</H2>
      <Flex justifyContent='center'>
        <ButtonGroupBadgesSources
          sources={sources}
          requests={acceptedRequests}
          currentSourceId={currentSourceId}
          onChange={(projectId) => setCurrentSourceId(projectId)}
        />
      </Flex>
      <StyledTable>
        <BaseTable data={requestsTableData} columns={requestsTableColumns} isSortable={true} />
      </StyledTable>
      {currentSourceId > 0 && (
        <Flex justifyContent='center' className='py-6'>
          <Button
            variant='outlined'
            size='medium'
            type='submit'
            onClick={() => setCurrentSourceId(0)}>
            {t('pages.all_agreed_requests')}
          </Button>
        </Flex>
      )}
    </StyledPaper>
  )
}
