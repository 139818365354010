export type ReservationTimesStateT = {
  openDay: number
  openHour: number
  closeDay: number
  closeHour: number
}

export type DataRequestStateT = {
  name: 'PRIJATY' | 'NEPRIJATY' | 'POTVRZENY' | 'NEPOTVRZENY' | 'ZRUSENY' | 'ZRUSENY_VYLUKA_ZDROJE'
  kod: 1 | 2 | 3 | 4 | 5 | 6
  vyznamCz: string
  popisCz: string
  vyznamEn: string
  popisEn: string
  zprac: boolean
}

export type DataRequestT = {
  id: number
  datum: string
  smenaPoradi: number
  zadani: {
    dny: {
      den: string
      hodinaOd: number
      hodinaDo: number
    }[]
  }
  stav: {
    name:
      | 'PRIJATY'
      | 'NEPRIJATY'
      | 'POTVRZENY'
      | 'NEPOTVRZENY'
      | 'ZRUSENY'
      | 'ZRUSENY_VYLUKA_ZDROJE'
    kod: 1 | 2 | 3 | 4 | 5 | 6
    vyznamCz: string
    popisCz: string
    vyznamEn: string
    popisEn: string
    zprac: boolean
  }
  usernameZadavatel: string
  datumZadavatel: string
}

export type DataRequestProjectT = {
  id: number
  nazevCz: string
  popisCz: string
  nazevEn: string
  popisEn: string
}

export type DataRequestContractT = {
  cislo: number
  popis: string
  fixVo: number
  fixMo: number
  platnostOd: string
}

export type DataRequestCustomerT = {
  nazev: string
  ico: string
  adresa: string
  jazyk: {
    name: string
    popis: string
  }
}

export type DataRequestProductT = {
  id: number
  nazevCz: string
  nazevEn: string
  popisCz: string
  popisEn: string
  uzivaNapajeni: boolean
}

export type DataAdditionalService = {
  id: number
  nazevCz: string
  nazevEn: string
  popisCz: string
  popisEn: string
}

export type DataSourceTypeT = {
  name: 'ZEL_INFRASTUKTURA' | 'HALY' | 'MISTNOSTI' | 'OSTATNI'
  vyznamCz: string
  popisCz: string
  vyznamEn: string
  popisEn: string
}

export type DataSourceT = {
  id: number
  nazevCz: string
  nazevEn: string
  typZdroje: DataSourceTypeT
  platnostOd: string
  usernameUprava: string
  datumUprava: string
}

export type DataProductRequestHoursRequestT = {
  pozadavek: DataRequestT
  projekt: DataRequestProjectT
  smlouva: DataRequestContractT
  zakaznik: DataRequestCustomerT
  produkt: DataRequestProductT
  zdroj: DataSourceT
  doplnkoveSluzby: DataAdditionalService[]
}

export type DataProductRequestHoursT = {
  hodina: number
  pozadavky: DataProductRequestHoursRequestT[]
}

export type DataProductRequestT = {
  pozadavkyNaProdukt: [
    {
      datum: string
      hodiny: DataProductRequestHoursT[]
    }
  ]
}

export type LanguageObject = { name: Language; popis: string }

export interface Customer {
  adresa: string
  ico: string
  id: number
  jazyk: LanguageObject
  nazev: string
  platnostOd: string | null
  platnostDo: string | null
}

export type UserDetail = {
  username: string
  jmeno: string
  prijmeni: string
  email: string
  telefon: string
  vuz: boolean
  role: string
  icoZakaznik: string
  platnostOd: number
  usernameVytvoreni: string
  datumVytvoreni: number
  usernameUprava: string
  datumUprava: number
}

export interface Uzivatel {
  email: UserDetail['email']
  jmeno: UserDetail['jmeno']
  prijmeni: UserDetail['prijmeni']
  role: UserDetail['role']
  telefon: UserDetail['telefon']
  vuz: UserDetail['vuz']
}
export interface CustomerDetailUzivatele {
  zakaznik: Customer
  uzivatele: Uzivatel[]
}

export interface Contract {
  cislo: string
  fixMo: number
  fixVo: number
  icoZakaznik: string
  id: number
  platnostOd: string | null
  popis: string
  platnostDo?: string | null
}

export type Language = 'CZ' | 'ENG'

export interface Product {
  datumUprava: string
  id: number
  idZdroj: number
  nazevCz: string
  nazevEn: string
  platnostOd: string
  usernameUprava: string
  popisCz: string
  popisEn: string
  uzivaNapajeni: boolean
  vylucny: boolean
  parkovani: boolean
  bezCasu: boolean
  doplnkovaSluzba: string
  platnostDo: string
  usernameZneplatneni: string
  datumZneplatneni: string
}

export type DataCustomerT = {
  id: number
  nazev: string
  ico: string
  adresa: string
  jazyk: {
    name: string
    popis: string
  }
  platnostOd: string | null
  platnostDo: string | null
}

export type DataUserT = {
  username: string
  jmeno: string
  prijmeni: string
  email: string
  telefon: string
  vuz: boolean
  role: string
  icoZakaznik: string
  platnostOd: number
  usernameVytvoreni: string
  datumVytvoreni: number
  usernameUprava: string
  datumUprava: number
}

export type DataNotificatonT = {
  id: number
  hlavicka: string
  obsah: string
  datumVytvoril: string
}

export type DataNotificationsT = {
  read?: boolean
  notifikace: DataNotificatonT
  uzivatel: {
    jmeno: string
    prijmeni: string
    email: string
    zakaznik: string
  }
  pozadavek?: DataRequestT
  projekt?: {
    id: number
    nazevCz: string
    popisCz: string
    nazevEn: string
    popisEn: string
  }
  smlouva?: {
    cislo: string
    popis: string
    fixVo: number
    fixMo: number
    platnostOd: string
  }
  produkt?: {
    id: number
    nazevCz: string
    popisCz: string
    nazevEn: string
    popisEn: string
    uzivaNapajeni: boolean
  }
  zdroj?: DataSourceT
  idProdukt?: number
  novinka?: {
    id: number
    nazev: string
    popis: string
    obsah: string
    usernameVytvoreni: string
    datumVytvoreni: string
    usernameUprava: string
    datumUprava: string
  }
}

export type DataRequestProductSourceTypeStateT = {
  datum: string
  pozadavky: DataProductRequestHoursRequestT[]
}

export type SelectListT = {
  name: string | number
  id: number | string
  ico: string
  value?: any
}

export interface IPostActionSpecifyRequest {
  datum: string
  idProdukt: number | null
  idProjekt: number
  doplnkovaSluzba: string[] | string
  smenaPoradi: number
  casOd?: number
  casDelka?: number
  poznamka: string
}

export interface IPostActionSpecifyRequestForUser extends IPostActionSpecifyRequest {
  usernameZadavatel: DataCustomerT['nazev']
}

export interface Request {
  id: number
  zdroj: {
    nazevCz: string
    nazevEn: string
    popisCz: string
    popisEn: string
  }
  stav: DataRequestStateT
  termin: {
    terminOd: string
    terminDo: string
  }
  kolize: number
  fixni: boolean
  zadavatel: {
    zakaznik: Pick<Customer, 'adresa' | 'ico' | 'nazev'>
    uzivatel: Pick<
      UserDetail,
      'email' | 'jmeno' | 'prijmeni' | 'role' | 'telefon' | 'vuz' | 'username'
    > & {
      jazyk: LanguageObject
    }
  }
  doplnkoveSluzby: number
  vytvoreni: string
  terminObsazeny: boolean
}

export interface RequestDetail {
  id: number
  stav: DataRequestStateT
  podrobnosti: {
    zadavatel: {
      uzivatel: Pick<UserDetail, 'username'>
      zakaznik: Pick<Customer, 'adresa' | 'ico' | 'nazev'>
    }
    kolize: number
    fixni: boolean
    projekt: DataRequestProjectT
    produkt: DataRequestProductT
    doplnkoveSluzby: DataAdditionalService[]
    posledniPoznamka: string
    terminObsazeny: boolean
  }
  zdroj: Pick<DataSourceT, 'nazevCz' | 'nazevEn'>
  poznamky: {
    typ: string
    uzivatel: Pick<UserDetail, 'email' | 'jmeno' | 'prijmeni' | 'role' | 'telefon' | 'vuz'> & {
      jazyk: LanguageObject
    }
    datum: string
    text: string
  }[]
  historie: [
    {
      datum: string
      uzivatel: Pick<UserDetail, 'email' | 'jmeno' | 'prijmeni' | 'role' | 'telefon' | 'vuz'> & {
        jazyk: LanguageObject
      }
      stav: DataRequestStateT
    }
  ]
}

export interface RequestDetailWithTimeSlots {
  datum: string
  casoveSloty: [
    {
      termin: {
        terminOd: string
        terminDo: string
      }
      zadosti: RequestDetail[]
    }
  ]
}

export type ReponseErrorT = {
  error: string
}

export type DataShiftsT = {
  smena: number
  pocet: number
}

export type DataProductRequestCountDaysT = {
  datum: string
  celyDen: number
  smeny: DataShiftsT[]
}

export type DataProductRequestCountSourceAllT = {
  zdroj: DataSourceT
  dny: DataProductRequestCountDaysT[]
}

export interface File {
  name: string
  saveDate: string
  size: number
}

export type FixedDemandSourceT = {
  vzo: number
  mzo: number
}

export type BearerTokenDecodeT = {
  jti: string
  iss: string
  sub: string
  aud: string
  iat: number
  exp: number
  role: string
}

export enum Languages {
  EN = 'en',
  CS = 'cs',
}
