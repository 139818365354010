import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import { Alert, Button, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler, UnpackNestedValue, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import {
  postActionSpecifyRequest,
  postActionSpecifyRequestForUser,
} from '../../api/action/specifyRequest'
import { GetDataAdditionalServiceT } from '../../api/data/additionalService'
import { getDataProductBySource, GetDataProductBySourceT } from '../../api/data/product'
import { ROLES } from '../../constants/roles'
import { ROUTES } from '../../constants/routes'
import { useApiValueInLang } from '../../hooks/useApiValueInLang'
import { useDispatch, useSelector } from '../../store/hooks'
import { reservationSlice } from '../../store/slices/reservation'
import { DataUserT } from '../../types/types'
import { ConvertArrayToString, ConvertObjectKeysToArray } from '../../utils/convert'
import { formatDate } from '../../utils/format'
import { isNullOrUndefinedOrEmpty } from '../../utils/validate'
import Flex from '../atoms/Flex'
import { H4 } from '../atoms/Heading'
import { FieldBaseCounterInput } from '../molecules/FieldBaseCounterInput'
import { FieldBaseSelect } from '../molecules/FieldBaseSelect'
import { TransitionAlertT } from '../molecules/TransitionAlert'

export interface ICustomerServiceSpecsFormInputs {
  produktId: number | null
  additionalServices: { [key: string]: boolean }
  comment: string
  hour: number
  hoursTotal: number
}

type PropsT = {
  onFormSubmit: (formData: UnpackNestedValue<ICustomerServiceSpecsFormInputs>) => void
  setAlertSuccess: (data: TransitionAlertT) => void
  currentSourceId: number
  currentProductDate: Date
  currentShiftNumber: number
  currentProjectId: number
  currentCustomerUsername?: DataUserT['username']
}

const FieldBaseLabel = tw.div`
  self-center
`

const StyledCommentWrapper = tw.div`
  mt-10
`

const StyledErrorWrapper = tw.div`
  mb-10
`

const StyledCommentTextArea = tw(TextareaAutosize)`
  border
  border-gray-300
  bg-secondary
  rounded
  my-2
  p-4
  text-sm
  w-1/2
`

const HOURS_DATA_LIST = [
  { id: 1, name: '1:00' },
  { id: 2, name: '2:00' },
  { id: 3, name: '3:00' },
  { id: 4, name: '4:00' },
  { id: 5, name: '5:00' },
  { id: 6, name: '6:00' },
  { id: 7, name: '7:00' },
  { id: 8, name: '8:00' },
  { id: 9, name: '9:00' },
  { id: 10, name: '10:00' },
  { id: 11, name: '11:00' },
  { id: 12, name: '12:00' },
  { id: 13, name: '13:00' },
  { id: 14, name: '14:00' },
  { id: 15, name: '15:00' },
  { id: 16, name: '16:00' },
  { id: 17, name: '17:00' },
  { id: 18, name: '18:00' },
  { id: 19, name: '19:00' },
  { id: 20, name: '20:00' },
  { id: 21, name: '21:00' },
  { id: 22, name: '22:00' },
  { id: 23, name: '23:00' },
  { id: 24, name: '00:00' },
]

export const CustomerServiceSpecsForm: React.FC<PropsT> = ({
  onFormSubmit,
  setAlertSuccess,
  currentSourceId,
  currentProductDate,
  currentShiftNumber,
  currentProjectId,
  currentCustomerUsername = '',
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  const { getNazev } = useApiValueInLang()

  const [additionalServices, setAdditionalServices] = useState<GetDataAdditionalServiceT[]>([])
  const [selectedSourceData, setSelectedSourceData] = useState<GetDataProductBySourceT[]>([])
  const [isProducttWithoutTime, setIsProducttWithoutTime] = useState<boolean>(false)
  const isAdmin = useSelector(
    (state) => state.user.role === ROLES.ADMIN || state.user.role === ROLES.VUZ_SPRAVCE
  )
  const isEditor = useSelector((state) => state.user.role === ROLES.VUZ_EDITOR)

  const {
    handleSubmit,
    register,
    watch,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ICustomerServiceSpecsFormInputs>({
    defaultValues: {
      hour: 12,
      hoursTotal: 8,
      comment: '',
      produktId: null,
    },
  })

  // Select first radiobutton if only one product
  useEffect(() => {
    if (selectedSourceData.length === 1)
      reset({ produktId: selectedSourceData && selectedSourceData[0]?.produkt?.id })
  }, [selectedSourceData])

  const watchProductId = watch('produktId')

  const handleOnClickCounterButtons = (action: 'increase' | 'decrease', value: number) => {
    if (value < 1 || value >= 24) return
    switch (action) {
      case 'increase':
        // eslint-disable-next-line consistent-return
        return setValue('hoursTotal', value + 1)
      case 'decrease':
        // eslint-disable-next-line consistent-return
        return value > 1 && setValue('hoursTotal', value - 1)
      default:
        // eslint-disable-next-line consistent-return
        return null
    }
  }

  useEffect(() => {
    const filteredSelectedSourceData =
      watchProductId &&
      selectedSourceData.find((value) => value.produkt.id.toString() === watchProductId.toString())
    filteredSelectedSourceData && setAdditionalServices(filteredSelectedSourceData.doplnkoveSluzby)
    filteredSelectedSourceData &&
      setIsProducttWithoutTime(filteredSelectedSourceData.produkt.bezCasu)
  }, [watchProductId])

  const loadData = async () =>
    await getDataProductBySource(currentSourceId).then((productsBySource) => {
      setSelectedSourceData(productsBySource)
    })

  useEffect(() => {
    currentSourceId && loadData()
  }, [])

  const onSubmit: SubmitHandler<ICustomerServiceSpecsFormInputs> = (data) => {
    onFormSubmit(data)
    const formatDataAdditionalServices = !isNullOrUndefinedOrEmpty(data.additionalServices)
      ? ConvertArrayToString(ConvertObjectKeysToArray(data.additionalServices))
      : ''

    const formattedDate = formatDate(currentProductDate, 'DD.MM.YYYY')

    const dataRequestRequired = {
      datum: formattedDate,
      idProdukt: watchProductId,
      idProjekt: currentProjectId,
      doplnkovaSluzba: formatDataAdditionalServices,
      smenaPoradi: currentShiftNumber,
      poznamka: data.comment,
    }

    const dataRequest = isProducttWithoutTime
      ? dataRequestRequired
      : {
          casOd: data.hour,
          casDelka: data.hoursTotal,
          ...dataRequestRequired,
        }

    isAdmin || isEditor
      ? postActionSpecifyRequestForUser({
          ...dataRequest,
          usernameZadavatel: currentCustomerUsername,
        }).then((response) => {
          if (response && response.error) {
            setAlertSuccess({
              title: t('pages.failed_create_request'),
              desc: response.error.message.toString(),
              icon: <DoNotDisturbIcon />,
              variant: 'error',
            })
          } else {
            setAlertSuccess({
              title: t('pages.success_create_request'),
              desc: t('pages.success_create_request_desc', { date: dataRequest.datum }),
              icon: <DoneOutlinedIcon />,
              variant: 'success',
            })
          }
        })
      : postActionSpecifyRequest(dataRequest).then((response) => {
          if (response && response.error) {
            setAlertSuccess({
              title: t('pages.failed_create_request'),
              desc: response.error.message.toString(),
              icon: <DoNotDisturbIcon />,
              variant: 'error',
            })
          } else {
            setAlertSuccess({
              title: t('pages.success_create_request'),
              desc: t('pages.success_create_request_desc', { date: dataRequest.datum }),
              icon: <DoneOutlinedIcon />,
              variant: 'success',
            })
          }
        })

    dispatch(
      reservationSlice.actions.update({
        currentCustomerUsername: '',
      })
    )

    navigate(ROUTES(resolvedLanguage).CREATE_RESERVATION)

    reset()
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {selectedSourceData && selectedSourceData.length > 0 && (
          <Flex>
            <Controller
              name='produktId'
              control={control}
              rules={{ required: t('pages.required_service_specs') }}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  {selectedSourceData.map((sourceData, index) => (
                    <FormControlLabel
                      key={index}
                      value={field.value}
                      control={<Radio value={sourceData.produkt.id} />}
                      label={getNazev(sourceData.produkt)}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </Flex>
        )}
        {additionalServices && additionalServices.length > 0 && (
          <div className='mt-10'>
            <H4>{t('pages.additional_services')}</H4>
            <Flex flexDirection='column'>
              {additionalServices.map((service) => (
                <FormControlLabel
                  key={service.id}
                  control={<Checkbox />}
                  label={getNazev(service)}
                  {...register(`additionalServices.${service.id.toString()}`)}
                />
              ))}
            </Flex>
          </div>
        )}
        {currentShiftNumber === 0 && !isProducttWithoutTime && (
          <Flex className='gap-6 max-w-400' alignItems='center'>
            <Flex className='gap-3 flex-1'>
              <FieldBaseLabel>{t('pages.hour')}</FieldBaseLabel>
              <FieldBaseSelect
                name='hour'
                control={control}
                label={t('pages.hour')}
                data={HOURS_DATA_LIST}
              />
            </Flex>
            <Flex className='gap-3 flex-1 max-w-250'>
              <FieldBaseLabel>{t('pages.length')}</FieldBaseLabel>
              <FieldBaseCounterInput
                label='hoursTotal'
                name='hoursTotal'
                control={control}
                handleOnClickCounterButtons={handleOnClickCounterButtons}
              />
            </Flex>
            h
          </Flex>
        )}
        <StyledCommentWrapper>
          <h4>
            <strong>{t('modals.note_optional')}</strong>
          </h4>
          <StyledCommentTextArea aria-label='minimum height' minRows={4} {...register('comment')} />
        </StyledCommentWrapper>
        {errors && Object.keys(errors).length > 0 && (
          <StyledErrorWrapper>
            <Alert variant='filled' severity='error'>
              {errors.produktId?.message}
            </Alert>
          </StyledErrorWrapper>
        )}
        <Flex justifyContent='end'>
          <Button variant='contained' size='large' type='submit' disabled={currentProjectId === 0}>
            {t('pages.send_reservation')}
          </Button>
        </Flex>
      </form>
    </>
  )
}
