import styled from '@emotion/styled'
import * as React from 'react'
import { Column, useTable } from 'react-table'
import tw from 'tailwind-styled-components'

import { COLORS } from '../../constants/styles'
import rem from '../../utils/styles/rem'
type PropsT = {
  columns: Array<Column<object>>
  data: Array<object>
}

const Table = tw.table`
  w-full
  text-sm
`

const Thead = tw.thead`
  bg-aliceBlue
`

const ThStyled = styled.th`
  &:first-of-type {
    width: ${rem(200)};
  }

  &:not(:first-of-type) {
    text-align: left;
  }
`

const TrStyled = styled.tr`
  td:first-of-type {
    font-size: 16px;
    padding: 14px;
    text-transform: capitalize;
    vertical-align: text-top;
    text-align: right;
  }
  td:not(:first-of-type) {
    background-color: ${COLORS.grey['100']};
  }
`

const Tr = tw(TrStyled)`
`

const Th = tw(ThStyled)`
  p-3
  border
  border-gray-200
`

export const ScheduleTable: React.FC<PropsT> = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <Table {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup) => {
          const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
          return (
            <tr key={key} {...restHeaderGroupProps}>
              {headerGroup.headers.map((column) => {
                const { key, ...restColumn } = column.getHeaderProps()
                return (
                  <Th key={key} {...restColumn}>
                    {column.render('Header')}
                  </Th>
                )
              })}
            </tr>
          )
        })}
      </Thead>
      <tbody {...getTableBodyProps}>
        {rows.map((row) => {
          prepareRow(row)
          const { key, ...restRowProps } = row.getRowProps()
          return (
            <Tr key={key} {...restRowProps}>
              {row.cells.map((cell) => {
                const { key, ...restCellProps } = cell.getCellProps()
                return (
                  <td className='border border-gray-200' key={key} {...restCellProps}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </Tr>
          )
        })}
      </tbody>
    </Table>
  )
}
