import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { getDataConfigParametersCode } from '../../api/data/configParameters'
import { getDataCustomer } from '../../api/data/customer'
import { DataProjectInfoT, getDataProjectInfo } from '../../api/data/project'
import { getDataSourceBySourceType, getDataSourceTypeAll } from '../../api/data/source'
import { getDataUser, getDataUserActive } from '../../api/data/user'
import { ROLES } from '../../constants/roles'
import { GetVyznamT, useApiValueInLang } from '../../hooks/useApiValueInLang'
import { useSelector } from '../../store/hooks'
import { reservationSlice } from '../../store/slices/reservation'
import {
  DataCustomerT,
  DataSourceT,
  DataSourceTypeT,
  DataUserT,
  ReservationTimesStateT,
} from '../../types/types'
import { isNullOrUndefinedOrEmpty } from '../../utils/validate'
import { BaseBreadcrumbs } from '../atoms/BreadCrumbs'
import Flex from '../atoms/Flex'
import { H1, H2, H3 } from '../atoms/Heading'
import { SourceTypeIcons } from '../atoms/SourceTypeIcons'
import StyledPaper from '../atoms/StyledPaper'
import { BaseTabs, TabObject } from '../molecules/BaseTabs'
import { TimeSlotsInfoBox } from '../molecules/TimeSlotsInfoBox'
import { TransitionAlert, TransitionAlertT } from '../molecules/TransitionAlert'
import {
  CustomerServiceSpecsForm,
  ICustomerServiceSpecsFormInputs,
} from '../organisms/CustomerServiceSpecsForm'
import { CustomerTimeSlotsForm, ICustomerTimeSlotsForm } from '../organisms/CustomerTimeSlotsForm'
import { BaseContainer } from '../templates/BaseContainer'

type PropsT = {}

const StyledServiceSpecs = tw.div`
  mt-16
`

const StyledTimeSlotsWrapper = tw.div`
  mt-8
`

type CurrentCustomerT = {
  name: DataCustomerT['nazev']
  ico: DataCustomerT['ico']
  username: DataUserT['username']
}

type SelectListT = {
  name: DataCustomerT['nazev']
  ico: DataCustomerT['ico']
  username: DataUserT['username']
}

const createTabs = (sourceData: DataSourceTypeT[], getVyznam: GetVyznamT): TabObject[] => {
  return sourceData.map((source) => ({
    name: source.name,
    label: getVyznam(source),
    icon: <SourceTypeIcons sourceType={source} />,
  }))
}

export const CreateReservationPage: React.FC<PropsT> = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { getNazev, getVyznam } = useApiValueInLang()

  const [isLoadingDataConfig, setIsLoadingDataConfig] = useState<boolean>(true)
  const [isFixedTime, setIsFixedTime] = useState<boolean>(false)
  const [reservationTimes, setReservationTimes] = useState<ReservationTimesStateT>({
    openDay: 0,
    openHour: 0,
    closeDay: 0,
    closeHour: 0,
  })

  const [successAlertOpen, setSuccessAlertOpen] = useState<boolean>(false)
  const [currentProjectName, setCurrentProjectName] = useState<string>('')
  const [currentProjectId, setCurrentProjectId] = useState<number>(0)
  const [projects, setProjects] = useState<DataProjectInfoT[]>([])
  const [sources, setSources] = useState<DataSourceTypeT[]>([])
  const [sourceChildren, setSourceChildren] = useState<DataSourceT[]>([])
  const [currentSource, setCurrentSource] = useState<string>('')
  const [currentSourceId, setCurrentSourceId] = useState<number>(0)
  const [currentProductDate, setCurrentProductDate] = useState<Date>(new Date())
  const [currentProductName, setCurrentProductName] = useState<string>('')
  const [currentShiftNumber, setCurrentShiftNumber] = useState<number>(0)
  const [tabs, setTabs] = useState<TabObject[]>([])
  const [currentCustomer, setCurrentCustomer] = useState<CurrentCustomerT>({
    name: '',
    ico: '',
    username: '',
  })
  const [customersList, setCustomersList] = useState<SelectListT[]>([
    {
      name: '',
      ico: '',
      username: '',
    },
  ])
  const [reservationAlert, setReservationAlert] = useState<TransitionAlertT>({
    title: '',
    desc: '',
    variant: 'success',
    icon: <></>,
  })

  const [currentDate, setCurrentDate] = useState(new Date(Date.now() + 3600 * 1000 * 24 * 7))
  const [timeSlotsSubmitted, setTimeSlotsSubmitted] = useState<boolean>(false)
  const [customerTimeSlotsFormData, setCustomerTimeSlotsFormData] =
    useState<ICustomerTimeSlotsForm>({ shift: '' })

  const isAdmin = useSelector(
    (state) => state.user.role === ROLES.ADMIN || state.user.role === ROLES.VUZ_SPRAVCE
  )
  const isEditor = useSelector((state) => state.user.role === ROLES.VUZ_EDITOR)

  const reservationCurrentCustomerUsername = useSelector(
    (state) => state.reservation.currentCustomerUsername
  )
  const userUsername = useSelector((state) => state.user.username)
  const isReservationOpen = useSelector((state) => state.reservation.isOpen)
  // const isReservationOpen = true
  const userIcoZakaznik = useSelector((state) => state.user.icoZakaznik)
  const isInProgress = useSelector((state) => state.reservation.isInProgress)

  const resetIsInProgress = () =>
    dispatch(
      reservationSlice.actions.update({
        isInProgress: false,
      })
    )

  const setIsReservationOpen = (isOpen: boolean) => {
    if (isOpen !== isReservationOpen) {
      dispatch(
        reservationSlice.actions.update({
          isOpen,
        })
      )
    }
  }

  const checkOpenTime = useCallback(() => {
    const dayNow = dayjs().day()
    const hoursNow = dayjs().hour()

    if (dayNow >= reservationTimes.openDay && dayNow <= reservationTimes.closeDay) {
      if (dayNow == reservationTimes.openDay && hoursNow >= reservationTimes.openHour) {
        if (hoursNow >= reservationTimes.openHour) setIsFixedTime(true)
      }
      if (dayNow == reservationTimes.closeDay && hoursNow < reservationTimes.closeHour)
        setIsFixedTime(true)
      if (dayNow > reservationTimes.openDay && dayNow < reservationTimes.closeDay)
        setIsFixedTime(true)
    } else {
      setIsFixedTime(false)
    }
  }, [reservationTimes])

  const handleSetCurrenCustomer = (value: string) => {
    const cutomerFromLoadedArray = customersList.find((customer) => {
      return (
        customer.name === value && {
          name: customer.name,
          ico: customer.ico,
        }
      )
    })
    resetForm()
    cutomerFromLoadedArray && setCurrentCustomer(cutomerFromLoadedArray)
  }

  const handleSetCurrentProject = ({ target: { value } }: SelectChangeEvent<string>) => {
    resetForm()
    setCurrentProjectName(value)
  }

  const getCurrentProjectId = projects.find(
    (project) => getNazev(project.projekt) === currentProjectName
  )?.projekt.id

  const getCurrentProjectsByIco = projects.filter(
    (project) => project.zakaznik.ico === currentCustomer.ico
  )

  const getCurrentProductName = (sourceChildrenArr: DataSourceT[], currentSourceId: number) => {
    if (currentSourceId && sourceChildrenArr.length > 0) {
      const product = sourceChildrenArr.find((sourceChild) => sourceChild.id === currentSourceId)
      return product ? getNazev(product) : ''
    }
    return ''
  }

  const handleSuccessAlertClose = () => {
    return setSuccessAlertOpen(false)
  }

  const handleSuccessAlertOpen = (alert: TransitionAlertT) => {
    setReservationAlert(alert)
    return setSuccessAlertOpen(true)
  }

  const handleTimeSlotClick = (productId: number, date: Date) => {
    setCurrentSourceId(productId)
    setCurrentProductDate(date)
    // @ts-ignore
    setCurrentProductName(getCurrentProductName(sourceChildren, productId))
  }

  const onSubmitCustomerTimeSlotsForm = (formData: ICustomerTimeSlotsForm) => {
    setCustomerTimeSlotsFormData(formData)
    setCurrentShiftNumber(parseInt(formData.shift))
    dispatch(
      reservationSlice.actions.update({
        isInProgress: true,
      })
    )
    return setTimeSlotsSubmitted(true)
  }

  const onChangeSourceTabs = (currentSourceName: string) => {
    return setCurrentSource(currentSourceName)
  }

  const onResetCustomerTimeSlotsForm = () => {
    resetIsInProgress()
    setCurrentProductName('')
    return setTimeSlotsSubmitted(false)
  }

  const onSubmitCustomerServiceSpecsForm = (formData: ICustomerServiceSpecsFormInputs) => {
    window.scrollTo(0, 0)
    if (formData) {
      setTimeSlotsSubmitted(false)
      setTimeout(() => {
        dispatch(
          reservationSlice.actions.update({
            isInProgress: false,
          })
        )
      }, 500)
      resetForm(true)
    }
  }

  const resetForm = (noProject?: boolean) => {
    onResetCustomerTimeSlotsForm()
    setCurrentSourceId(0)
    setCurrentProductDate(new Date())
    setCurrentProductName('')
    setCurrentShiftNumber(0)
    if (!noProject) {
      setCurrentProjectName('')
      setCurrentProjectId(0)
    }
    setCustomerTimeSlotsFormData({ shift: '' })
    setCurrentDate(new Date(Date.now() + 3600 * 1000 * 24 * 7))
  }

  useEffect(() => {
    setIsReservationOpen(isFixedTime)
  }, [isReservationOpen, isFixedTime])

  useEffect(() => {
    const fetchDataConfigParameters = async () => {
      Promise.all([
        getDataConfigParametersCode('FIXNI_POZADAVKY_OTEVRENI_DEN_V_TYDNU'),
        getDataConfigParametersCode('FIXNI_POZADAVKY_OTEVRENI_HODINA'),
        getDataConfigParametersCode('FIXNI_POZADAVKY_UZAVRENI_DEN_V_TYDNU'),
        getDataConfigParametersCode('FIXNI_POZADAVKY_UZAVRENI_HODINA'),
      ]).then((values) => {
        if (values[0] && values[1] && values[2] && values[3]) {
          setReservationTimes({
            openDay: values[0].hodnota,
            openHour: values[1].hodnota,
            closeDay: values[2].hodnota,
            closeHour: values[3].hodnota,
          })
        }
      })
      setIsLoadingDataConfig(false)
    }
    fetchDataConfigParameters().catch(console.error)
    resetIsInProgress()
  }, [])

  // Check time every 10 seconds for reservation open status
  useEffect(() => {
    let checkDateInterval: NodeJS.Timeout
    if (!isLoadingDataConfig) {
      checkOpenTime()
      checkDateInterval = setInterval(() => {
        checkOpenTime()
      }, 10000)
    }
    return () => clearInterval(checkDateInterval)
  }, [isLoadingDataConfig, reservationTimes])

  useEffect(() => {
    setTabs(createTabs(sources, getVyznam))
  }, [sources])

  // Initial values for customer and project selects
  useEffect(() => {
    Promise.all([getDataUserActive(), getDataCustomer()]).then((values) => {
      if (values[0] && values[0].length > 0 && values[1] && values[1].length > 0) {
        const customersList = values[0].map((customer): SelectListT => {
          return {
            name: `${customer.jmeno} ${customer.prijmeni}${
              values[1] &&
              ', ' + values[1].find((company) => company.ico === customer.icoZakaznik)?.nazev
            }`,
            ico: customer.icoZakaznik,
            username: customer.username,
          }
        })
        setCustomersList(customersList)
        const currentCustomer = customersList.find((customer) => customer.username === userUsername)
        getDataUser(reservationCurrentCustomerUsername || currentCustomer?.username || '').then(
          (user) => {
            if (user) {
              const ico = user.icoZakaznik
              setCurrentCustomer({
                name: `${user.jmeno} ${user.prijmeni}${
                  values[1] && ', ' + values[1].find((company) => company.ico === ico)?.nazev
                }`,
                ico,
                username: user.username,
              })
            }
          }
        )
      }
    })
  }, [])

  useEffect(() => {
    getDataProjectInfo().then((projects) => {
      if (projects && projects.length > 0) {
        const projectsForUser =
          isAdmin || isEditor
            ? projects.filter((project) => project.zakaznik.ico === currentCustomer.ico)
            : projects.filter((project) => project.zakaznik.ico === userIcoZakaznik)
        const currentProject = projectsForUser.find(
          (project) => project.projekt.id.toString() === params.projectId
        )?.projekt
        const projectCurrentName = currentProject ? getNazev(currentProject) : ''

        setCurrentProjectName(
          projectCurrentName ? projectCurrentName : getNazev(projectsForUser[0].projekt)
        )
        projectsForUser && setProjects(projectsForUser)
      }
    })

    getDataSourceTypeAll().then((sources) => {
      sources && setSources(sources)
    })
  }, [currentCustomer])

  useEffect(() => {
    params && params.projectId && setCurrentProjectId(parseInt(params.projectId))
  }, [params])

  // Initial value for projects select
  useEffect(() => {
    getCurrentProjectId && setCurrentProjectId(getCurrentProjectId)
  }, [getCurrentProjectId])

  // Load sources for select the first item of tabs
  useEffect(() => {
    !isNullOrUndefinedOrEmpty(sources) && onChangeSourceTabs(sources[0].name)
  }, [sources])

  // Load source children after select source
  useEffect(() => {
    if (currentSource) {
      getDataSourceBySourceType(currentSource).then((source) => {
        source && setSourceChildren(source)
      })
    }
  }, [currentSource])

  const handleSetCurrentDate = (newDate: Date) => {
    const now = new Date()
    if (newDate <= now) {
      return
    }
    setCurrentDate(newDate)
  }

  return (
    <BaseContainer>
      <BaseBreadcrumbs />
      <div className='my-3'>
        <TransitionAlert
          title={reservationAlert.title}
          desc={reservationAlert.desc}
          icon={reservationAlert.icon}
          variant={reservationAlert.variant}
          open={successAlertOpen}
          setOpen={handleSuccessAlertClose}
        />
      </div>
      <Flex justifyContent='space-between' alignItems='center' className='mt-7 mb-7'>
        <H1 noMargin>{t('pages.create_reservation')}</H1>
        {isFixedTime && ['MZO', 'VZO', 'STR', 'LTR'].includes(currentProductName) && (
          <TimeSlotsInfoBox
            currentDate={currentDate}
            currentCustomer={isAdmin || isEditor ? currentCustomer.username : userUsername}
            currentProductName={currentProductName}
            projectId={currentProjectId}
          />
        )}
      </Flex>
      <StyledPaper>
        <H2>{t('modals.project')}</H2>
        {isAdmin || isEditor ? (
          <Flex className='gap-6'>
            <div className='w-1/2'>
              <InputLabel>{t('modals.customer')}</InputLabel>
              <Select
                value={currentCustomer.name}
                defaultValue={currentCustomer.name}
                onChange={(e) => {
                  handleSetCurrenCustomer(e.target.value.toString())
                }}
                className='w-full border-none bg-gray-100'>
                {customersList &&
                  customersList.length > 0 &&
                  customersList.map((customer, index) => (
                    <MenuItem key={index} value={customer.name}>
                      {customer.name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            {currentCustomer && (
              <div className='w-1/2'>
                <InputLabel>{t('modals.project')}</InputLabel>
                <Select
                  native
                  value={currentProjectName}
                  onChange={handleSetCurrentProject}
                  className='w-full border-none bg-gray-100'
                  inputProps={{
                    id: 'select-multiple-native',
                  }}>
                  {getCurrentProjectsByIco &&
                    getCurrentProjectsByIco.length > 0 &&
                    getCurrentProjectsByIco.map((project) => (
                      <option key={project.projekt.id} value={getNazev(project.projekt)}>
                        {getNazev(project.projekt)}
                      </option>
                    ))}
                </Select>
              </div>
            )}
          </Flex>
        ) : (
          <>
            <H3>{t('pages.service_specification')}</H3>
            <Select
              native
              value={currentProjectName}
              onChange={handleSetCurrentProject}
              className='w-full border-none bg-gray-100'
              inputProps={{
                id: 'select-multiple-native',
              }}>
              {projects &&
                projects.length > 0 &&
                projects.map((project) => (
                  <option key={project.projekt.id} value={getNazev(project.projekt)}>
                    {getNazev(project.projekt)}
                  </option>
                ))}
            </Select>
          </>
        )}
        <StyledTimeSlotsWrapper>
          <BaseTabs
            value={currentSource}
            onChange={onChangeSourceTabs}
            tabs={tabs}
            disabled={isInProgress}
          />
          <CustomerTimeSlotsForm
            onFormSubmit={onSubmitCustomerTimeSlotsForm}
            onFormReset={onResetCustomerTimeSlotsForm}
            submitted={timeSlotsSubmitted}
            submittedData={customerTimeSlotsFormData}
            headers={sourceChildren}
            onTimeSlotClick={handleTimeSlotClick}
            selectedDate={currentProductDate}
            currentProductName={currentProductName}
            currentDate={currentDate}
            setCurrentDate={handleSetCurrentDate}
          />
          {timeSlotsSubmitted && (
            <StyledServiceSpecs>
              <H3>{t('pages.service_specification')}</H3>
              <CustomerServiceSpecsForm
                onFormSubmit={onSubmitCustomerServiceSpecsForm}
                setAlertSuccess={handleSuccessAlertOpen}
                currentSourceId={currentSourceId}
                currentProductDate={currentProductDate}
                currentShiftNumber={currentShiftNumber}
                currentProjectId={currentProjectId}
                currentCustomerUsername={currentCustomer.username}
              />
            </StyledServiceSpecs>
          )}
        </StyledTimeSlotsWrapper>
      </StyledPaper>
    </BaseContainer>
  )
}
