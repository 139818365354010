import React from 'react'
import tw from 'tailwind-styled-components'

import { REQUEST } from '../../constants/request'
import { useApiValueInLang } from '../../hooks/useApiValueInLang'
import { DataSourceT } from '../../types/types'
import { Request } from '../../types/types'
import { H2 } from '../atoms/Heading'
import { SourceRequestBox } from '../atoms/SourceRequestBox'
import { SourceTypeIcons } from '../atoms/SourceTypeIcons'

type PropsT = {
  title: string
  requests: Request[]
  sources: DataSourceT[]
}

const StyledContainer = tw.div`
  flex
  flex-col
  justify-center
  bg-secondary-dark
  w-full
  p-10
`

const StyledSatisfiedServicesContainer = tw.div`
  grid
  grid-cols-6
  gap-3
  min-h-10
`

const StyledSatisfiedServiceItem = tw.div`
  col-span-1
`

export const SatisfiedService: React.ComponentType<PropsT> = ({ title, requests, sources }) => {
  const acceptedRequests = requests.filter((request) => request.stav.kod === REQUEST.STATE.ACCEPTED)
  const { getNazev } = useApiValueInLang()

  return (
    <StyledContainer>
      <H2 centered>{title}</H2>
      <StyledSatisfiedServicesContainer>
        {sources.map((source) => (
          <StyledSatisfiedServiceItem key={source.id}>
            <SourceRequestBox
              icon={<SourceTypeIcons sourceType={source.typZdroje} />}
              title={getNazev(source)}
              requestsQuantity={
                acceptedRequests.filter((item) => getNazev(item.zdroj) === getNazev(source)).length
              }
            />
          </StyledSatisfiedServiceItem>
        ))}
      </StyledSatisfiedServicesContainer>
    </StyledContainer>
  )
}
