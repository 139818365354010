import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import DescriptionIcon from '@mui/icons-material/Description'
import DoneIcon from '@mui/icons-material/Done'
import LogoutIcon from '@mui/icons-material/Logout'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import RemoveDoneOutlinedIcon from '@mui/icons-material/RemoveDoneOutlined'
import { Avatar, Badge, Box, IconButton, Menu, MenuItem, MenuProps } from '@mui/material'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import { alpha, styled } from '@mui/material/styles'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { delAdminNotification, putAdminNotification } from '../../api/admin/notifications'
import { getDataNotificationsUserName } from '../../api/data/notifications'
import { API } from '../../constants/api'
import { FORMAT } from '../../constants/format'
import { ROUTES } from '../../constants/routes'
import { COLORS } from '../../constants/styles'
import { useApiValueInLang } from '../../hooks/useApiValueInLang'
import { useDispatch, useSelector } from '../../store/hooks'
import { modalSlice } from '../../store/slices/modal'
import { userSlice } from '../../store/slices/user'
import {
  DataNotificationsT,
  DataRequestProductT,
  DataRequestProjectT,
  DataRequestT,
  DataSourceT,
} from '../../types/types'
import { ConvertArrayToString } from '../../utils/convert'
import { formatDateWithIncomingFormat } from '../../utils/format'
import { removeFromLocalStorage } from '../../utils/localStorage'
import Flex from '../atoms/Flex'
import { H3 } from '../atoms/Heading'
import { MoreOptions } from '../atoms/MoreOptions'
import { SourceTypeIcons } from '../atoms/SourceTypeIcons'
import { LanguageSwitcher } from './LanguageSwitcher'

const StyledUserMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 7,
    marginTop: theme.spacing(2),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

const DisplayReadIcon: React.FC<{
  read: boolean
  notificationId: number
  handleReadNotification: (id: number) => void
}> = ({ read, handleReadNotification, notificationId }) => {
  return read ? (
    <RemoveDoneOutlinedIcon />
  ) : (
    <DoneIcon className='cursor-pointer' onClick={() => handleReadNotification(notificationId)} />
  )
}

const DisplayRequestTime: React.FC<{
  request: DataRequestT
  source: DataSourceT
  product: DataRequestProductT
  project: DataRequestProjectT
}> = ({ request, source, product, project }) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  const { getNazev } = useApiValueInLang()
  return (
    <>
      {getNazev(source)}, {getNazev(product)} {t('components.for')} {getNazev(project)}{' '}
      {request?.zadani?.dny?.length > 1
        ? `${t('components.from')} ${formatDateWithIncomingFormat(
            request.zadani.dny[0].den,
            FORMAT.API_DDMMYYYY,
            'DD. MMM',
            resolvedLanguage
          )} ${request.zadani.dny[0].hodinaOd}:00 ${t(
            'components.to'
          )} ${formatDateWithIncomingFormat(
            request.zadani.dny[1].den,
            FORMAT.API_DDMMYYYY,
            'DD. MMM',
            resolvedLanguage
          )} ${request.zadani.dny[1].hodinaDo}:00`
        : `, ${formatDateWithIncomingFormat(
            request.zadani.dny[0].den,
            FORMAT.API_DDMMYYYY,
            'DD. MMM',
            resolvedLanguage
          )} ${request.zadani.dny[0].hodinaOd}:00-${request.zadani.dny[0].hodinaDo}:00`}
    </>
  )
}

export const ClientSection: React.FC = () => {
  const dispatch = useDispatch()
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()

  const getFirstName = useSelector((state) => state.user.firstName)
  const getLastName = useSelector((state) => state.user.lastName)
  const userUsername = useSelector((state) => state.user.username)

  const [notifications, setNotifications] = useState<DataNotificationsT[]>([])
  const [notificationsRead, setNotificationsRead] = useState<DataNotificationsT[]>([])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [notificationsEl, setNotificationsEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const openNotifications = Boolean(notificationsEl)
  const isInProgress = useSelector((state) => state.reservation.isInProgress)
  const location = useLocation()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClickNotifications = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationsEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCloseNotifications = () => {
    setNotificationsEl(null)
  }

  const handleLogout = () => {
    dispatch(userSlice.actions.reset())
    dispatch(modalSlice.actions.reset())

    removeFromLocalStorage(API.BEARER_TOKEN)
  }

  const handleReadNotification = (notificationId: DataNotificationsT['notifikace']['id']) => {
    const setClickedNotificationAsRead = sortedNotifications.map((notification) =>
      notification.notifikace.id === notificationId ? { ...notification, read: true } : notification
    )
    putAdminNotification(notificationId)
    setNotificationsRead(setClickedNotificationAsRead)
  }

  const handleReadNotificationAll = () => {
    const setAllNotificationAsRead = sortedNotifications.map((notification) => ({
      ...notification,
      read: true,
    }))
    const notificationIds = ConvertArrayToString(
      setAllNotificationAsRead.map((notification) => notification.notifikace.id)
    )
    setNotificationsRead(setAllNotificationAsRead)
    putAdminNotification(notificationIds)
  }

  const handleDeleteNotificaton = (notificationId: DataNotificationsT['notifikace']['id']) => {
    const removedIdFromNotifications = sortedNotifications.filter(
      (notification) => notification.notifikace.id !== notificationId
    )
    delAdminNotification(notificationId)
    setNotificationsRead(removedIdFromNotifications)
  }

  const handleDeleteNotificatonAll = () => {
    const notificationIds = ConvertArrayToString(
      sortedNotifications.map((notification) => notification.notifikace.id)
    )
    delAdminNotification(notificationIds)
    setNotificationsRead([])
    setNotifications([])
  }

  const sortedNotifications = useMemo(() => {
    const notificationRead =
      notificationsRead && notificationsRead.length > 0
        ? notificationsRead.map((notification) => ({
            read: false,
            ...notification,
          }))
        : notifications.map((notification) => ({
            read: false,
            ...notification,
          }))

    return notificationRead.sort((a, b) => b.notifikace.id - a.notifikace.id)
  }, [notifications, handleReadNotification])

  const showNotificationsCount = useMemo(
    () =>
      notificationsRead?.length > 0
        ? notificationsRead.filter((notification) => notification.read === false).length
        : notifications.length,
    [notificationsRead, notifications]
  )

  useEffect(() => {
    if (userUsername) {
      setTimeout(() => {
        getDataNotificationsUserName(userUsername).then((notifications) => {
          notifications && setNotifications(notifications)
        })
      }, 1000)
    }
  }, [userUsername, isInProgress, location])

  return (
    <Flex justifyContent='center'>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton onClick={handleClickNotifications} size='small' sx={{ ml: 2 }}>
          <Avatar
            sx={{
              bgcolor: COLORS.primary.light,
              width: '32px',
              height: '32px',
              overflow: 'visible',
            }}>
            <Badge badgeContent={showNotificationsCount} color='primary'>
              <NotificationsNoneIcon fontSize='small' className='text-primary-contrast' />
            </Badge>
          </Avatar>
        </IconButton>
        {notifications && notifications.length > 0 && (
          <StyledUserMenu
            id='profileMenu'
            MenuListProps={{
              'aria-labelledby': 'profileMenu-button',
            }}
            anchorEl={notificationsEl}
            open={openNotifications}
            onClose={handleCloseNotifications}>
            <Flex justifyContent='space-between' alignItems='center' className='p-3'>
              <H3>{t('components.notifications')}</H3>
              <Flex>
                <div className='cursor-pointer' onClick={() => handleReadNotificationAll()}>
                  <u>{t('components.mark_as_read')}</u>
                </div>
                <MoreOptions
                  options={[
                    {
                      id: 1,
                      name: t('components.delete_all'),
                      action: () => handleDeleteNotificatonAll(),
                    },
                  ]}
                />
              </Flex>
            </Flex>
            <Divider sx={{ my: 0.5 }} />
            {sortedNotifications.length > 0 &&
              sortedNotifications.map((sortedNotification) => {
                const { notifikace, zdroj, read, produkt, projekt, pozadavek, novinka } =
                  sortedNotification
                if (notifikace) {
                  return (
                    <div
                      key={notifikace.id}
                      className={
                        read
                          ? 'p-3 max-w-520 bg-secondary-dark'
                          : 'p-3 max-w-520 bg-secondary-light'
                      }>
                      <Flex>
                        <Avatar>
                          <div className='text-secondary-contrast'>
                            {zdroj && <SourceTypeIcons sourceType={zdroj.typZdroje} />}
                            {novinka && <DescriptionIcon />}
                          </div>
                        </Avatar>
                        <Flex flexDirection='column' className='ml-2 w-full'>
                          <Flex justifyContent='space-between'>
                            <Flex justifyContent='space-between' className='w-full'>
                              <strong style={{ marginRight: '10px' }}>
                                <h5>{notifikace.hlavicka}</h5>
                              </strong>
                              {`${formatDateWithIncomingFormat(
                                notifikace.datumVytvoril,
                                'DD.MM.YYYY HH:mm:ss',
                                'dddd DD.MMM HH:mm',
                                resolvedLanguage
                              )}`}
                            </Flex>
                            <Flex className='ml-2' alignItems='start'>
                              <DisplayReadIcon
                                read={read}
                                handleReadNotification={handleReadNotification}
                                notificationId={notifikace.id}
                              />
                              <MoreOptions
                                options={[
                                  {
                                    id: 1,
                                    name: t('components.delete'),
                                    action: () => handleDeleteNotificaton(notifikace.id),
                                  },
                                ]}
                              />
                            </Flex>
                          </Flex>
                          {/* <div className='mt-1 text-xs'>{notifikace.obsah}</div> */}
                          <div className='mt-1 text-xs'>
                            {pozadavek && produkt && projekt && zdroj && (
                              <DisplayRequestTime
                                request={pozadavek}
                                product={produkt}
                                project={projekt}
                                source={zdroj}
                              />
                            )}
                            {novinka && (
                              <>
                                {t('components.last_change', {
                                  name: novinka.nazev,
                                  date: novinka.datumUprava,
                                })}
                              </>
                            )}
                          </div>
                        </Flex>
                      </Flex>
                    </div>
                  )
                }
                return []
              })}
          </StyledUserMenu>
        )}

        <IconButton onClick={handleClick} size='small' sx={{ ml: 1 }}>
          <Avatar
            sx={{
              bgcolor: COLORS.primary.light,
              fontSize: '0.875rem',
              fontWeight: 500,
              width: '32px',
              height: '32px',
            }}>
            {getFirstName && getFirstName.charAt(0)}
            {getLastName && getLastName.charAt(0)}
          </Avatar>
        </IconButton>
        <div className='text-primary-contrast'>
          <Button
            id='profileMenu-button'
            aria-controls='profileMenu'
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            disableElevation
            color='secondary'
            onClick={handleClick}
            endIcon={<ArrowDropDownOutlinedIcon color='secondary' />}>
            <span className='normal-case'>{`${getFirstName} ${getLastName}`}</span>
          </Button>
        </div>
        <StyledUserMenu
          id='profileMenu'
          MenuListProps={{
            'aria-labelledby': 'profileMenu-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}>
          <Link onClick={() => handleLogout()} to={ROUTES(resolvedLanguage).LOGIN}>
            <MenuItem onClick={handleClose} disableRipple>
              <LogoutIcon />
              {t('components.logout')}
            </MenuItem>
          </Link>
        </StyledUserMenu>
        <LanguageSwitcher />
      </Box>
    </Flex>
  )
}
