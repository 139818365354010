import styled from '@emotion/styled'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, useTable } from 'react-table'
import tw from 'tailwind-styled-components'

import { getDataConfigParameters } from '../../api/data/configParameters'
import { getDataProductRequestCountSourceAll } from '../../api/data/productRequest'
import { useApiValueInLang } from '../../hooks/useApiValueInLang'
import { useSelector } from '../../store/hooks'
import { DataProductRequestCountSourceAllT, DataSourceT } from '../../types/types'
import { formatDate, getFirstDayWeek, getLastDayWeek } from '../../utils/format'
import rem from '../../utils/styles/rem'
import {
  ITimeSlotsTableData,
  timeSlotsTableData,
  timeSlotsTableDataColumns,
} from './TimeSlotsTableData'

type PropsT = {
  headers: DataSourceT[]
  disabled?: boolean
  onTimeSlotClick: (columnId: number, date: Date) => void
  currentDate: Date
  setCurrentDate: (date: Date) => void
  handleSubmit: () => void
}

export type ConfigParametersShiftsT = {
  shiftHoursLength: number
  maxShiftHoursPerDay: number
  firstShiftHour: number
}

const Table = tw.table`
  w-full
  text-sm
`

const Thead = tw.thead`
  bg-aliceBlue
`

const ThStyled = styled.th`
  &:first-of-type {
    width: ${rem(200)};
  }

  &:not(:first-of-type) {
    text-align: left;
  }
`

const Th = tw(ThStyled)`
  p-3
  border
  border-gray-200
`

export const TimeSlotsTable: React.FC<PropsT> = ({
  headers,
  disabled = false,
  onTimeSlotClick,
  currentDate,
  setCurrentDate,
  handleSubmit,
}) => {
  const [requestCountSource, setRequestCountSource] = useState<DataProductRequestCountSourceAllT[]>(
    []
  )
  const [configParametersShifts, setConfigParametersShifts] = useState<ConfigParametersShiftsT>({
    shiftHoursLength: 8,
    maxShiftHoursPerDay: 3,
    firstShiftHour: 2,
  })
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  const { getNazev } = useApiValueInLang()

  const isInProgress = useSelector((state) => state.reservation.isInProgress)

  const handleChangeDate = (action: string) => {
    const prevDate = new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 7))
    const nextDate = new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() + 7))

    switch (action) {
      case 'nextWeek':
        return setCurrentDate(nextDate)
      case 'prevWeek':
        return setCurrentDate(prevDate)
      default:
        return null
    }
  }

  useEffect(() => {
    getDataProductRequestCountSourceAll(
      formatDate(getFirstDayWeek(currentDate), 'DD.MM.YYYY'),
      formatDate(getLastDayWeek(currentDate), 'DD.MM.YYYY')
    ).then((value) => setRequestCountSource(value))
    getDataConfigParameters().then((value) => {
      if (value && value.length > 0) {
        setConfigParametersShifts({
          shiftHoursLength: value.find((value) => value.kod === 'SMENA_POCET_HODIN')?.hodnota || 0,
          maxShiftHoursPerDay:
            value.find((value) => value.kod === 'MAX_POCET_POZADAVKU')?.hodnota || 0,
          firstShiftHour:
            value.find((value) => value.kod === 'SMENA_POCATECNI_CAS_DNE')?.hodnota || 0,
        })
      }
    })
  }, [currentDate, isInProgress])

  const data = useMemo<ITimeSlotsTableData[]>(
    () =>
      timeSlotsTableData(
        headers,
        disabled,
        currentDate,
        requestCountSource,
        configParametersShifts,
        t,
        resolvedLanguage
      ),
    [headers, disabled, currentDate, configParametersShifts, requestCountSource]
  )

  const columns = useMemo<Column<ITimeSlotsTableData>[]>(
    () =>
      timeSlotsTableDataColumns(headers, currentDate, handleChangeDate, getNazev, resolvedLanguage),
    [headers, currentDate]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <Table {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup) => {
          const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
          return (
            <tr key={key} {...restHeaderGroupProps}>
              {headerGroup.headers.map((column) => {
                const { key, ...restColumn } = column.getHeaderProps()
                return (
                  <Th key={key} {...restColumn}>
                    {column.render('Header')}
                  </Th>
                )
              })}
            </tr>
          )
        })}
      </Thead>
      <tbody {...getTableBodyProps}>
        {rows.map((row) => {
          prepareRow(row)
          const { key, ...restRowProps } = row.getRowProps()
          return (
            <tr key={key} {...restRowProps}>
              {row.cells.map((cell) => {
                const { key, ...restCellProps } = cell.getCellProps()
                return (
                  <td
                    className='p-2 border border-gray-200'
                    key={key}
                    onClick={async () => {
                      if (!disabled) {
                        await onTimeSlotClick(parseInt(cell.column.id), row.cells[0].value.key)
                        return handleSubmit()
                      }
                      return undefined
                    }}
                    {...restCellProps}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
