import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  getDataProductRequestState,
  getProductRequestNoDuplicates,
} from '../../api/data/productRequest'
import { getRequests } from '../../api/data/requests'
import { getDataSource, getDataSourceTypeAll } from '../../api/data/source'
import {
  DataProductRequestHoursRequestT,
  DataSourceT,
  DataSourceTypeT,
  Request,
} from '../../types/types'
import { formatDate } from '../../utils/format'
import { SatisfiedService } from '../molecules/SatisfiedService'
import { AgreedRequests } from '../organisms/AgreedRequests'
import { FastReservationAdmin } from '../organisms/FastReservationAdmin'
import { InfoBoxOpeningTime } from '../organisms/InfoBoxOpeningTime'
import { NearestReservationsAdmin } from '../organisms/NearestReservationsAdmin'
import { NewsBackOfficeOverview } from '../organisms/NewsBackOfficeOverview'
import { BaseContainer, BaseContainerItem } from '../templates/BaseContainer'

export const OverviewPageAdmin: React.ComponentType = () => {
  const [sources, setSource] = useState<DataSourceT[]>([])
  const [sourcesType, setSourcesType] = useState<DataSourceTypeT[]>([])
  const [productRequests, setProductRequests] = useState<DataProductRequestHoursRequestT[]>([])
  const [productRequestsAccepted, setProductRequestsAccepted] = useState<Request[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    Promise.all([
      getDataSource(),
      getDataProductRequestState(
        formatDate(dayjs().add(7, 'day').day(1), 'DD.MM.YYYY'),
        formatDate(dayjs().add(7, 'day').day(7), 'DD.MM.YYYY'),
        'POTVRZENY'
      ),
      getDataSourceTypeAll(),
      getRequests(),
    ]).then((values) => {
      values[0] && setSource(values[0])
      if (values[1]) {
        setProductRequests(getProductRequestNoDuplicates(values[1]))
      }
      if (values[2]) {
        setSourcesType(values[2])
      }
      if (values[3]) {
        setProductRequestsAccepted(values[3])
      }
    })
  }, [])

  return (
    <BaseContainer>
      <InfoBoxOpeningTime
        icon={<EventNoteOutlinedIcon fontSize='inherit' />}
        titleOnOpen={t('pages.on_open')}
        titleOnClose={t('pages.on_close')}
        descOnOpen={t('pages.desc_on')}
        descOnClose={t('pages.desc_on')}
      />
      <BaseContainerItem>
        <SatisfiedService
          title={t('pages.solved_requests')}
          requests={productRequestsAccepted}
          sources={sources}
        />
      </BaseContainerItem>
      <BaseContainerItem>
        <FastReservationAdmin title={t('pages.create_quick_reservation')} />
      </BaseContainerItem>
      <BaseContainerItem>
        <AgreedRequests
          title={t('pages.accepted_requests')}
          requests={productRequests}
          sources={sources}
        />
      </BaseContainerItem>
      <BaseContainerItem>
        <NearestReservationsAdmin
          title={t('pages.closest_reservations')}
          sourcesType={sourcesType}
          sources={sources}
        />
      </BaseContainerItem>
      <BaseContainerItem>
        <NewsBackOfficeOverview title={t('pages.lastest_operational_info')} />
      </BaseContainerItem>
    </BaseContainer>
  )
}
