import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'

import { DataProjectInfoT } from '../../api/data/project'
import { FORMAT } from '../../constants/format'
import { REQUEST } from '../../constants/request'
import { useApiValueInLang } from '../../hooks/useApiValueInLang'
import { DataProductRequestHoursRequestT, DataRequestT } from '../../types/types'
import { formatDateWithIncomingFormat } from '../../utils/format'
import Flex from '../atoms/Flex'
import { H2 } from '../atoms/Heading'
import StyledPaper from '../atoms/StyledPaper'
import TableWrapper from '../atoms/TableWrapper'
import { BaseTable } from '../molecules/BaseTable'
import { ButtonGroupBadges } from '../molecules/ButtonGroupBadges'

type PropsT = {
  title: string
  requests: DataProductRequestHoursRequestT[]
  projects: DataProjectInfoT[]
}

type RequestsFormattedArrayT = {
  time: JSX.Element
  state: string | JSX.Element
  timeslot: string
  project: string
  product: string
}

export const CurrentRequests: React.FC<PropsT> = ({ title, requests, projects }) => {
  const [currentProjectId, setCurrentProjectId] = useState<number>(0)
  const [requestsToRender, setRequestsToRender] = useState<RequestsFormattedArrayT[]>([])
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  const { getNazev, getVyznam } = useApiValueInLang()

  const requestsToShowFirstTime = 7

  const requestsTableColumns = React.useMemo(
    () => [
      {
        Header: t('pages.last_activity'),
        accessor: 'time',
        disableFilters: true,
        sortType: (a: Row<any>, b: Row<any>) => {
          const rowA = new Date(a.original.timeRaw).getTime()
          const rowB = new Date(b.original.timeRaw).getTime()
          if (rowA > rowB) return -1
          if (rowB > rowA) return 1
          return 0
        },
      },
      {
        Header: t('pages.state'),
        accessor: 'state',
        disableFilters: true,
      },
      {
        Header: t('pages.time_slot'),
        accessor: 'timeslot',
        disableFilters: true,
        sortType: (a: Row<any>, b: Row<any>) => {
          const rowA = new Date(a.original.timeRaw).getTime()
          const rowB = new Date(b.original.timeRaw).getTime()
          if (rowA > rowB) return -1
          if (rowB > rowA) return 1
          return 0
        },
      },
      {
        Header: t('pages.project'),
        accessor: 'project',
        disableFilters: true,
      },
      {
        Header: t('modals.product'),
        accessor: 'product',
        disableFilters: true,
      },
    ],
    []
  )

  const getIcon = (
    stateCode: DataRequestT['stav']['kod'],
    stateName: DataRequestT['stav']['vyznamCz'] | DataRequestT['stav']['vyznamEn']
  ) => {
    switch (stateCode) {
      case REQUEST.STATE.ACCEPTED:
        return (
          <>
            <ChangeCircleOutlinedIcon fontSize='small' sx={{ color: ' #fcc100' }} /> {stateName}
          </>
        )
      case REQUEST.STATE.NOT_RECEIVED:
        return (
          <>
            <CloseIcon fontSize='small' color='disabled' /> {stateName}
          </>
        )
      case REQUEST.STATE.CONFIRMED:
        return (
          <>
            <CheckCircleOutlineIcon fontSize='small' color='success' /> {stateName}
          </>
        )
      case REQUEST.STATE.NOT_CONFIRMED:
        return (
          <>
            <ChangeCircleOutlinedIcon fontSize='small' sx={{ color: ' #fcc100' }} /> {stateName}
          </>
        )
      case REQUEST.STATE.CANCELED:
        return (
          <>
            <CloseIcon fontSize='small' color='disabled' /> {stateName}
          </>
        )
      case REQUEST.STATE.CANCELED_SOURCE_EXCLUSION:
        return (
          <>
            <CloseIcon fontSize='small' color='disabled' /> {stateName}
          </>
        )
      default:
        return stateName
    }
  }

  const requestsFormattedArray = React.useMemo(() => {
    const requestsArr =
      currentProjectId > 0
        ? requests.filter((request) => request.projekt.id === currentProjectId)
        : requests
    return requestsArr.map((request) => ({
      time: (
        <>
          {formatDateWithIncomingFormat(
            request.pozadavek.datumZadavatel,
            FORMAT.API_DDMMYYYY_HHMM,
            'DD. MMM., HH:mm:ss',
            resolvedLanguage
          )}
          ,{' '}
        </>
      ),
      timeRaw: request.pozadavek.datumZadavatel,
      state: getIcon(request.pozadavek.stav.kod, getVyznam(request.pozadavek.stav)),
      timeslot: `${formatDateWithIncomingFormat(
        request.pozadavek.datum,
        FORMAT.API_DDMMYYYY,
        'DD. MMM.',
        resolvedLanguage
      )}, ${request.pozadavek.zadani.dny[0].hodinaOd}:00-${
        request.pozadavek.zadani.dny[0].hodinaDo
      }:00`,
      project: getNazev(request.projekt),
      product: getNazev(request.produkt),
    }))
  }, [requests, currentProjectId])

  const sliceRequests = (start: number, end: number) => {
    const slicedRequests = requestsFormattedArray.slice(start, end)
    setRequestsToRender(slicedRequests)
  }

  useEffect(() => {
    if (requestsFormattedArray && requestsFormattedArray.length > requestsToShowFirstTime) {
      sliceRequests(0, requestsToShowFirstTime)
    } else {
      handleShowAllRequests()
    }
  }, [requestsFormattedArray])

  const handleShowAllRequests = () => {
    setRequestsToRender(requestsFormattedArray)
  }

  const requestsTableData = React.useMemo(
    () => requestsToRender,
    [requestsToRender, currentProjectId]
  )

  return (
    <StyledPaper>
      <H2 centered>{title}</H2>
      <Flex justifyContent='center'>
        <ButtonGroupBadges
          projects={projects}
          requests={requests}
          currentProjectId={currentProjectId}
          onChange={(projectId) => setCurrentProjectId(projectId)}
        />
      </Flex>
      <TableWrapper>
        <BaseTable columns={requestsTableColumns} data={requestsTableData} isSortable={true} />
      </TableWrapper>
      {requestsToRender &&
        requestsFormattedArray &&
        requestsToRender.length === requestsToShowFirstTime &&
        requestsFormattedArray.length > requestsToShowFirstTime && (
          <Flex justifyContent='center' className='mt-6'>
            <Button variant='outlined' size='medium' type='submit' onClick={handleShowAllRequests}>
              {t('pages.all_requests')}
            </Button>
          </Flex>
        )}
    </StyledPaper>
  )
}
