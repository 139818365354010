import { Grid, Pagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DataNewsT, getNews, getNewsCount } from '../../api/data/news'
import { ROLES } from '../../constants/roles'
import { ROUTES } from '../../constants/routes'
import { useSelector } from '../../store/hooks'
import { H1 } from '../atoms/Heading'
import StyledPaper from '../atoms/StyledPaper'
import { NewsPreviewPost } from '../molecules/NewsPreviewPost'

type NewsListPropsT = {
  title: string
}

export const NewsList: React.FC<NewsListPropsT> = ({ title }) => {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation()
  const [news, setNews] = useState<DataNewsT[]>([])
  const [newsCount, setNewsCount] = useState<number>(0)
  const [page, setPage] = React.useState(1)
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const isAdmin = useSelector(
    (state) => state.user.role === ROLES.ADMIN || state.user.role === ROLES.VUZ_SPRAVCE
  )
  const isEditor = useSelector((state) => state.user.role === ROLES.VUZ_EDITOR)
  const editNewsArticleModal = useSelector((state) => state.modal.editNewsArticle)
  const removeNewsArticleModal = useSelector((state) => state.modal.removeNewsArticle)

  const loadData = async () => {
    const data = await Promise.all([getNews(5, (page - 1) * 5), getNewsCount()])
    setNews(data[0])
    setNewsCount(data[1])
  }

  useEffect(() => {
    loadData()
  }, [editNewsArticleModal, removeNewsArticleModal, page])

  return (
    <StyledPaper>
      <Grid container justifyContent='center'>
        <Grid item className='p-10 w-full'>
          <Grid container direction='column' spacing={0} alignItems='center'>
            <Grid item>
              <H1 size='H2' centered>
                {title}
              </H1>
            </Grid>
            <Grid item className='w-full'>
              <Grid container direction='column' rowGap={5} alignItems='center'>
                {news &&
                  news.map((article) => (
                    <Grid key={article.id} container justifyContent='center'>
                      <Grid item xs={12} lg={8}>
                        <NewsPreviewPost
                          id={article.id}
                          url={`${ROUTES(resolvedLanguage).NEWS_DETAIL}/${article.id}`}
                          title={article.nazev}
                          date={article.datumVytvoreni}
                          dateIcon
                          content={article.popis}
                          editable={isAdmin || isEditor}
                        />
                      </Grid>
                    </Grid>
                  ))}

                <Pagination
                  count={Math.ceil(newsCount / 5)}
                  page={page}
                  onChange={handleChange}
                  size='large'
                  color='primary'
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledPaper>
  )
}
