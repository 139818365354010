import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'

import logo from '../../../assets/img/logo.png'
import { ROUTES } from '../../../constants/routes'
import { COLORS } from '../../../constants/styles'
import Container from '../../atoms/Container'
import Flex from '../../atoms/Flex'
import { ClientSection } from '../../molecules/ClientSection'
import { LanguageSwitcher } from '../../molecules/LanguageSwitcher'
import { Submenu } from '../../molecules/SubMenu'

const StyledHeader = styled.header`
  background-color: ${COLORS.primary.main};
`

type HeaderPropsT = {
  auth: boolean | undefined
}

const StyledHeaderLine = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  z-index: 9999;
`

const StyledTWHeaderLine = tw(StyledHeaderLine)`
  items-center
  py-3
  shadow-xs
  relative
`

export const Header: React.FC<HeaderPropsT> = ({ auth }) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  return (
    <StyledHeader>
      <StyledTWHeaderLine>
        <Container>
          <Flex justifyContent='space-between' alignItems='center'>
            <Flex alignItems='center'>
              <Link to={ROUTES(resolvedLanguage).DASHBOARD}>
                <img src={logo} alt='Logo' width={84} height={50} />
              </Link>
              <Link className='no-underline' to={ROUTES(resolvedLanguage).DASHBOARD}>
                <nav className='ml-4'>
                  <span className='text-secondary text-xl font-bold'>{t('general.portal')}</span>
                </nav>
              </Link>
            </Flex>
            {auth && <ClientSection />}
            {!auth && <LanguageSwitcher />}
          </Flex>
        </Container>
      </StyledTWHeaderLine>
      {auth && <Submenu />}
    </StyledHeader>
  )
}
