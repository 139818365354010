import { Endpoints } from '../../constants/endpoints'
import { Customer, CustomerDetailUzivatele } from '../../types/types'
import { getApiData } from '../_GLOBAL/getApiData'

export const getDataCustomerId = (id: string) => getApiData<Customer>(Endpoints.dataCustomerId(id))

export const getDataCustomerIdUser = (id: string) =>
  getApiData<CustomerDetailUzivatele>(Endpoints.dataCustomerIdUser(id))

export const getDataCustomer = () => getApiData<Customer[]>(Endpoints.dataCustomer())
