import DoneIcon from '@mui/icons-material/Done'
import { Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { confirmRequestList, confirmRequestListT } from '../../../api/action/specifyRequest'
import { MODALS } from '../../../constants/modals'
import { useSnackbar } from '../../../hooks/useSnackbar'
import { useDispatch, useSelector } from '../../../store/hooks'
import { modalSlice } from '../../../store/slices/modal'
import { BaseInput } from '../../atoms/BaseInput'
import Flex from '../../atoms/Flex'
import { Modal } from '../../molecules/Modal'

export const AcceptRequestDialog: React.FC = () => {
  const [message, setMessage] = useState('')
  const isOpen = useSelector((state) => state.modal.acceptRequestModal)
  const requestId = useSelector((state) => state.modal.acceptRequestId)
  const dispatch = useDispatch()
  const [displaySnackBarWithMessage] = useSnackbar()
  const { t } = useTranslation()

  const handleClose = () => {
    setMessage('')
    dispatch(
      modalSlice.actions.update({
        [MODALS.ACCEPT_REQUEST_MODAL]: false,
        [MODALS.ACCEPT_REQUEST_ID]: 0,
      })
    )
  }

  const acceptRequests = async (idArray: confirmRequestListT[]) => {
    dispatch(
      modalSlice.actions.update({
        [MODALS.ACCEPT_REQUEST_IS_SENDING]: true,
      })
    )
    const response = await confirmRequestList(idArray)
    const multipleOrOneId = idArray.length === 1 ? t('modals.request') : t('modals.all_requests')
    if (response.some(() => 'error' in response)) {
      displaySnackBarWithMessage({
        message: t('modals.failed_to_accept', { multipleOrOneId }),
        isOpen: true,
        severity: 'error',
      })
      handleClose()
    } else {
      displaySnackBarWithMessage({
        message: t('modals.success_to_accept', { multipleOrOneId }),
        isOpen: true,
        severity: 'success',
      })
      dispatch(
        modalSlice.actions.update({
          [MODALS.ACCEPT_REQUEST_IS_SENDING]: false,
        })
      )
      handleClose()
    }
  }

  return (
    <Modal open={isOpen} modalId={MODALS.ACCEPT_REQUEST_MODAL} onClose={handleClose}>
      <div className='mb-2'>{t('modals.note_optional')}</div>
      <div>
        <BaseInput
          fullWidth
          multiline
          rows={6}
          label=''
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>

      <Flex justifyContent='end' className='mt-4'>
        <Button
          variant='contained'
          size='large'
          onClick={() => {
            if (requestId) {
              acceptRequests([{ id: requestId, poznamka: message }])
            }
          }}
          startIcon={<DoneIcon />}>
          {t('modals.confirm_request')}
        </Button>
      </Flex>
    </Modal>
  )
}
