import React from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'tailwind-styled-components'

import { FORMAT } from '../../../../constants/format'
import { RequestDetail } from '../../../../types/types'
import { formatDateWithIncomingFormat } from '../../../../utils/format'

interface NotesContentProps {
  notes: RequestDetail['poznamky']
}

const DetailContentWrapper = tw.div`
  grid
  gap-2
  text-sm
  py-3
`

export const NotesContent: React.FC<NotesContentProps> = ({ notes }) => {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation()
  return (
    <DetailContentWrapper>
      <div className='mb-2'>
        {/* <BaseButton variant='outlined' startIcon={<AddCircleOutlineIcon />}>*/}
        {/*  Přidat poznámku*/}
        {/* </BaseButton>*/}
      </div>
      {notes.map((note, index) => (
        <div key={index}>
          <div className='text-gray-500'>{`${note.uzivatel.jmeno} ${
            note.uzivatel.prijmeni
          } ${formatDateWithIncomingFormat(
            note.datum,
            FORMAT.API_DDMMYYYY_HHMM,
            'DD. MMM., HH:mm:ss',
            resolvedLanguage
          )} - ${note.typ}`}</div>
          <div>{note.text}</div>
        </div>
      ))}
    </DetailContentWrapper>
  )
}
