import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'

import { RequestDetailWithTimeSlots } from '../../../../types/types'
import { SelectedDetailSlotT } from './RequestDetailDialog'

interface DayItemProps {
  timeSlotsWithRequests: RequestDetailWithTimeSlots[]
  selectedSlotIndex: SelectedDetailSlotT
  setSelectedSlotIndex: ({ day, slot }: SelectedDetailSlotT) => void
  setSelectedRequestId: (requestId: number) => void
}

type StyledDayItemTimeSlotPropsT = {
  $selected: boolean
}

const StyledDays = styled.div`
  max-height: 500px;
`

const StyledDaysTW = tw(StyledDays)`
  bg-secondary
`

const StyledDayItem = tw.div`
  border-t
`

const StyledDayDay = tw.div`
  px-3
  pt-4
  uppercase
  font-black
`

const StyledDayItemTimeSlot = tw.div<StyledDayItemTimeSlotPropsT>`
  px-3
  py-2
  ${(p) => (p.$selected ? 'bg-primary' : '')}
  ${(p) => (p.$selected ? 'text-secondary' : '')}
`

export const DayItems: React.FC<DayItemProps> = ({
  timeSlotsWithRequests,
  selectedSlotIndex,
  setSelectedSlotIndex,
  setSelectedRequestId,
}) => {
  const { t } = useTranslation()
  return (
    <StyledDaysTW className='overflow-scroll'>
      {timeSlotsWithRequests.map((daySlot, dayIndex) => (
        <StyledDayItem key={dayIndex}>
          <StyledDayDay>{daySlot.datum}</StyledDayDay>

          {daySlot.casoveSloty.map((timeSlot, timeIndex) => (
            <StyledDayItemTimeSlot
              key={timeIndex}
              $selected={selectedSlotIndex.day === dayIndex && selectedSlotIndex.slot === timeIndex}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setSelectedSlotIndex({ day: dayIndex, slot: timeIndex })
                setSelectedRequestId(
                  timeSlotsWithRequests[dayIndex].casoveSloty[timeIndex].zadosti[0].id
                )
              }}>
              {t('modals.number_of_requests', {
                timeFrom: timeSlot.termin.terminOd,
                timeTo: timeSlot.termin.terminDo,
                count: timeSlot.zadosti.length,
              })}
            </StyledDayItemTimeSlot>
          ))}
        </StyledDayItem>
      ))}
    </StyledDaysTW>
  )
}
