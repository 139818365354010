import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { ROUTES } from '../../constants/routes'
import Flex from './Flex'

type PropsT = {
  icon: React.ReactNode
  title: string
  requestsQuantity: number
}

type StyledContainerPropsT = {
  $active: boolean
}

const StyledContainer = tw.div<StyledContainerPropsT>`
flex
p-2
border
${(p) => (p.$active ? 'bg-secondary-light' : 'bg-secondary-dark')}
rounded-md
border-gray-300
`

const StyledIcon = tw.div`
mr-1
`

const StyledDesc = tw.div`
text-xs
`

export const SourceRequestBox: React.ComponentType<PropsT> = ({
  icon,
  title,
  requestsQuantity,
}) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  const RenderSourceRequestBox = () => (
    <StyledContainer $active={requestsQuantity > 0}>
      <StyledIcon>{icon}</StyledIcon>
      <Flex justifyContent='center' flexDirection='column'>
        <strong>{title}</strong>
        <StyledDesc>
          {requestsQuantity > 0
            ? t('components.unchecked', { count: requestsQuantity })
            : t('components.all_checked')}
        </StyledDesc>
      </Flex>
    </StyledContainer>
  )

  return (
    <>
      {requestsQuantity > 0 ? (
        <Link
          className='no-underline'
          to={ROUTES(resolvedLanguage).RESERVATION}
          state={{ filterBySource: title }}>
          <RenderSourceRequestBox />
        </Link>
      ) : (
        <RenderSourceRequestBox />
      )}
    </>
  )
}
