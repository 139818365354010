import { Alert, AlertTitle, Button, TextField } from '@mui/material'
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import tw from 'tailwind-styled-components'

import { H1 } from '../atoms/Heading'
import { BaseContainer } from '../templates/BaseContainer'

interface IResetPasswordFormInput {
  resetInput: string
}

const StyledForm = tw.div`
flex
justify-center
`

const StyledFormContainer = tw.div`
grid
grid-cols-2
gap-6
`

const StyledFormTitle = tw.div`
col-span-full
text-center
`

const StyledFormAlert = tw.div`
col-span-full
`

const StyledFormInput = tw.div`
col-span-2
flex
justify-center
`

const StyledFormButton = tw.div`
flex
justify-center
col-span-full
`

export const ResetPasswordPage: React.FC = () => {
  const { control, handleSubmit } = useForm<IResetPasswordFormInput>()
  const { t } = useTranslation()

  const onSubmit: SubmitHandler<IResetPasswordFormInput> = (data) => {
    console.log(data)
  }

  return (
    <BaseContainer centered>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledForm>
          <StyledFormContainer>
            <StyledFormTitle>
              <H1 size='H1'>{t('pages.request_new_password')}</H1>
              <span>{t('pages.fill_email')}</span>
            </StyledFormTitle>

            <StyledFormAlert>
              <Alert severity='info'>
                <AlertTitle>{t('pages.warning')}</AlertTitle>
                {t('pages.process_request')}
              </Alert>
            </StyledFormAlert>

            <StyledFormInput>
              <Controller
                name='resetInput'
                control={control}
                defaultValue=''
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    className='w-full'
                    label={t('pages.your_email')}
                    variant='outlined'
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
                rules={{
                  required: t('pages.wrong_email'),
                }}
              />
            </StyledFormInput>

            <StyledFormButton>
              <div className='flex justify-center'>
                <Button variant='contained' size='large' type='submit'>
                  {t('pages.send_new_password')}
                </Button>
              </div>
            </StyledFormButton>
          </StyledFormContainer>
        </StyledForm>
      </form>
    </BaseContainer>
  )
}
