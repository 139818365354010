import 'react-quill/dist/quill.snow.css'

import CancelIcon from '@mui/icons-material/Cancel'
import { Button } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import ReactQuill from 'react-quill'
import tw from 'tailwind-styled-components'

import { putAdminNews } from '../../../api/admin/news'
import { getDataNewsFileRequests } from '../../../api/data/excel'
import { DataNewsT, getNewsArticleById } from '../../../api/data/news'
import { MODALS } from '../../../constants/modals'
import { useIsImageFileFormatAllowed } from '../../../hooks/useIsImageFileFormatAllowed'
import { useSnackbar } from '../../../hooks/useSnackbar'
import { useDispatch, useSelector } from '../../../store/hooks'
import { modalSlice } from '../../../store/slices/modal'
import Flex from '../../atoms/Flex'
import { FieldBaseInput } from '../../molecules/FieldBaseInput'
import { FieldDrozone } from '../../molecules/FieldDropzone'
import { Modal } from '../../molecules/Modal'
import { useTranslation } from 'react-i18next'

type PropsT = {}

interface IAddNewsArticleDialog {
  image: string
  title: string
  description: string
  date: string
  text: string
  rusenePrilohy: string[]
}

const StyledButtons = tw.div`
mt-6
flex
justify-center
gap-4
`

export const EditNewsArticleDialog: React.FC<PropsT> = () => {
  const [displaySnackBarWithMessage] = useSnackbar()
  const [blob, setBlob] = useState<Blob | undefined>()
  const [deletedImage, setDeletedImage] = useState<boolean>(false)
  const { t } = useTranslation()

  const [article, setArticle] = useState<Pick<DataNewsT, 'nazev' | 'popis' | 'obsah' | 'files'>>({
    nazev: '',
    popis: '',
    obsah: '',
    files: [],
  })

  const isOpened = useSelector((state) => state.modal.editNewsArticle)
  const articleId = useSelector((state) => state.modal.editNewsArticleId)

  const { control, handleSubmit, reset } = useForm<IAddNewsArticleDialog>()

  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(
      modalSlice.actions.update({
        [MODALS.EDIT_NEWS_ARTICLE]: false,
      })
    )
  }

  const handleDownloadPdf = (articleId: number, fileName: string) => {
    getDataNewsFileRequests(articleId, fileName)
      .then(function (response: any) {
        return new Blob([response.data], {
          type: response.headers['content-type'],
        })
      })
      .then(function (blob) {
        setBlob(blob)
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  const onSubmit: SubmitHandler<IAddNewsArticleDialog> = (data) => {
    const formData = new FormData()
    formData.append(
      'novinka',
      JSON.stringify({
        id: articleId,
        nazev: data.title,
        popis: data.description,
        obsah: data.text,
        rusenePrilohy: data.image || deletedImage ? article?.files?.[0]?.name : '',
      })
    )
    formData.append('files', data.image)

    putAdminNews(formData).then((response) => {
      handleClose()
      reset()
      if (response.some(() => 'error' in response)) {
        displaySnackBarWithMessage({
          message: t('modals.failed_agree'),
          isOpen: true,
          severity: 'error',
        })
      } else {
        displaySnackBarWithMessage({
          message: t('modals.success_agree'),
          isOpen: true,
          severity: 'success',
        })
      }
    })
  }

  useEffect(() => {
    reset({
      title: article.nazev,
      description: article.popis,
      text: article.obsah,
    })
  }, [article])

  useEffect(() => {
    getNewsArticleById(articleId).then((response) => {
      response &&
        setArticle({
          nazev: response.nazev,
          popis: response.popis,
          obsah: response.obsah,
          files: response.files,
        })
    })
    setDeletedImage(false)
  }, [isOpened])

  useEffect(() => {
    if (article.files?.length > 0) handleDownloadPdf(articleId, article.files[0].name)
  }, [article])

  return (
    <Modal open={isOpened} modalId={MODALS.EDIT_NEWS_ARTICLE}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDirection='column' className='gap-3'>
          {article.files?.length > 0 &&
          useIsImageFileFormatAllowed(article.files[0].name) &&
          blob &&
          !deletedImage ? (
            <div className='relative'>
              <CancelIcon
                className='absolute -right-4 -top-4'
                sx={{ fontSize: '3.25rem' }}
                color='error'
                onClick={() => setDeletedImage(true)}
              />
              <img src={URL.createObjectURL(blob)} alt='Background image' />
            </div>
          ) : (
            <FieldDrozone name='image' multiple={false} control={control} />
          )}
          <FieldBaseInput
            name='title'
            control={control}
            label={t('modals.news_title')}
            className='min-w-full'
            defaultValue={article.nazev}
            rules={{
              required: t('modals.enter_news_title'),
            }}
          />

          <FieldBaseInput
            name='description'
            control={control}
            label={t('modals.news_description')}
            className='min-w-full'
            rules={{
              required: t('modals.enter_news_description'),
            }}
          />

          <Controller
            name='text'
            control={control}
            render={({ field: { onChange, value } }) => (
              <ReactQuill value={value} onChange={onChange} />
            )}
          />

          <StyledButtons>
            <Button variant='contained' size='large' className='col-span-4' type='submit'>
              {t('general.save')}
            </Button>
            <Button
              variant='outlined'
              size='large'
              className='col-span-4'
              type='submit'
              onClick={() => handleClose()}>
              {t('general.drop')}
            </Button>
          </StyledButtons>
        </Flex>
      </form>
    </Modal>
  )
}
