import React from 'react'
import { useTranslation } from 'react-i18next'

import { NewsList } from '../organisms/NewsList'
import { BaseContainer } from '../templates/BaseContainer'

export const NewsPage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <BaseContainer>
      <NewsList title={t('pages.operational_info')} />
    </BaseContainer>
  )
}
