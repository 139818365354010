import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { Alert } from '@mui/material'
import React, { useEffect } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import tw from 'tailwind-styled-components'

import { DataSourceT } from '../../types/types'
import { formatDateLong } from '../../utils/format'
import { isNullOrUndefinedOrEmpty, isObjectEmpty } from '../../utils/validate'
import Flex from '../atoms/Flex'
import { TimeSlotsTable } from './TimeSlotsTable'

export type ICustomerTimeSlotsForm = {
  shift: string
}

type PropsT = {
  headers: DataSourceT[]
  onFormSubmit: (formData: ICustomerTimeSlotsForm) => void
  submitted: boolean
  submittedData: ICustomerTimeSlotsForm
  onFormReset: () => void
  onTimeSlotClick: (columnId: number, date: Date) => void
  selectedDate: Date
  currentProductName: string
  currentDate: Date
  setCurrentDate: (date: Date) => void
}

const StyledSubmittedInfo = tw(Flex)`
text-sm
my-6
`

const StyledResetButton = tw(HighlightOffIcon)`
ml-2
cursor-pointer
`

export const CustomerTimeSlotsForm: React.FC<PropsT> = ({
  headers,
  submitted,
  onFormSubmit,
  onFormReset,
  submittedData,
  onTimeSlotClick,
  selectedDate,
  currentProductName,
  currentDate,
  setCurrentDate,
}) => {
  const methods = useForm<ICustomerTimeSlotsForm>()
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  const {
    formState: { errors },
    reset,
  } = methods

  useEffect(() => {
    reset()
  }, [headers, currentDate])

  const onSubmit: SubmitHandler<ICustomerTimeSlotsForm> = (data) => {
    !isNullOrUndefinedOrEmpty(data) && onFormSubmit(data)
  }
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <TimeSlotsTable
          headers={headers}
          disabled={submitted}
          onTimeSlotClick={onTimeSlotClick}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          handleSubmit={methods.handleSubmit(onSubmit)}
        />
        {!isObjectEmpty(errors) && (
          <Alert variant='filled' severity='error'>
            {errors.shift?.message}
          </Alert>
        )}
        {submitted ? (
          <StyledSubmittedInfo alignItems='center'>
            {t('pages.selected')}
            <strong className='ml-2'>
              {currentProductName}, {formatDateLong(new Date(selectedDate), {}, resolvedLanguage)},{' '}
              {submittedData.shift === '0'
                ? t('pages.custom_time')
                : `${6 + 8 * parseInt(submittedData.shift) - 8}:00-${
                    6 + 8 * parseInt(submittedData.shift) - (submittedData.shift === '3' ? 24 : 0)
                  }:00`}
            </strong>
            <StyledResetButton
              color='error'
              onClick={() => {
                onFormReset()
                reset()
              }}
            />
          </StyledSubmittedInfo>
        ) : (
          <>
            <p className='text-center text-xs my-4 text-gray-500'>{t('pages.timeslots_info')}</p>
            {/* <Flex justifyContent='end'>*/}
            {/* <Button variant='contained' size='large' type='submit'>*/}
            {/*   Pokračovat*/}
            {/* </Button>*/}
            {/* </Flex>*/}
          </>
        )}
      </form>
    </FormProvider>
  )
}
