import { Endpoints } from '../../constants/endpoints'
import { FixedDemandSourceT } from '../../types/types'
import { getApiData } from '../_GLOBAL/getApiData'

export const getDataFixedDemandSource = (
  username: string,
  datumOd: string,
  datumDo: string,
  projectId: number
) =>
  getApiData<FixedDemandSourceT>(
    Endpoints.dataFixedDemandSource(username, datumOd, datumDo, projectId)
  )

export const getDataFixedDemandSourceAssigned = (
  username: string,
  datumOd: string,
  datumDo: string,
  projectId: number
) =>
  getApiData<FixedDemandSourceT>(
    Endpoints.dataFixedDemandSourceAssigned(username, datumOd, datumDo, projectId)
  )
